<template>
    
    <div>
        <div @click="active = true">
            <slot  name="button"></slot>
        </div>
        

        <transition name="modal">
            <div class="modal-mask" @click="active = false" v-if="active">
                <div class="modal-wrapper">
                    <div class="modal-container" :class="css" style="max-width: 600px;overflow-y: scroll;padding: 20px 30px;">
                        <button class="close-btn btn" @click="$emit('close')">
                            <font-awesome-icon :icon="['fal', 'times']"  full-width></font-awesome-icon>
                        </button>
                        <div class="modal-header" style="padding:0;border-color:#ebebeb;">
                            <h2 v-html="title" style=" margin-top:0;"></h2>
                        </div>

                        <div class="modal-body" style="padding:15px 0;">
                            <slot name="content"></slot>
                        </div>

<!--                    <div class="modal-footer">-->
<!--                        <button class="modal-default-button" @click="$emit('close')">-->
<!--                            Close-->
<!--                        </button>-->
<!--                    </div>-->
                    </div>
                </div>
            </div>
        </transition>
        
    </div>
    
</template>

<script>

    export default {

        name: "SimpleModal",

        props: [
            'title',
            'css',
            'width'
        ],

        data() {
            return {
                active:false
            }
        },

        mounted() {


        },

        methods: {
            
            openModal: function(){
                
                this.active = true;
                
            },

            closeModal: function(){

                this.active = false;

            }
            
        },
    }
</script>

<style scoped>



</style>