

module.exports = {

    $nav: null,
    $btn: null,
    $closeBtn: null,
    opened: false,
    activeClass:'',

    init: function ($nav, $btn, $closeBtn, activeClass) {
        let self = this;

        self.$nav = $nav;
        self.$btn = $btn;
        self.$closeBtn = $closeBtn;
        self.activeClass = activeClass;
        
        self.$btn.on('click', function (e) {
            e.preventDefault();
            if (!self.opened) {
                self.activate.call(self);
                $(this).off('click');
            }

        });

        self.$closeBtn.on('click', function (e) {
            e.preventDefault();
            self.deactivate.call(self);
        });
    },

    activate: function () {
        console.log('activate');
        
        let self = this;
        disableBodyScroll(self.$nav[0]);
        self.opened = true;
        //self.$nav.addClass('active visible');
        //self.$nav.addClass('visible');
        //$('main').addClass('showLeftNav');
        document.querySelector('body').classList.add(self.activeClass);

        
        
        setTimeout(function () {
            $('main > section, #Hero').on('click', $.proxy(self.deactivate, self));
        }, 250);

    },

    deactivate: function () {
        let self = this;
        self.opened = false;
        // self.$nav.removeClass('active').on('transitionend',function(){
        //     self.$nav.removeClass('visible').off('transitionend');
        // });
        // self.$nav.on('transitionend',function(){
        //     console.log('transition ended');
        //     self.$nav.off('transitionend');
        // });
        //$('main').removeClass('showLeftNav');
        enableBodyScroll(self.$nav[0]);
        document.querySelector('body').classList.remove(self.activeClass);
        
        
        $('main > section, #Hero').off('click');

        // re-attach click handler to element that initiates the opening:
        self.$btn.on('click', function (e) {
            e.preventDefault();
            $(this).off('click');
            self.activate.call(self);
        });
    }

};

