<template>
    <a href="#" 
       class="event-bin bin" 
       @click.prevent="handleShowPopup">

        <slot></slot>
        
    </a>
    
</template>

<script>
    import { mapState, mapMutations } from 'vuex';

    export default {

        name: "EventBinPopup",

        props: [
            'category',
            'title',
            'subTitle',
            'image',
            'view'
            
        ],

        data() {
            return {}
        },

        mounted() {


        },

        methods: {

            ...mapMutations(['storeSidePopupRequest']),


            handleShowPopup: function(){
                console.log('handleShowPopup');

                this.storeSidePopupRequest({
                    data:{
                        view:this.view
                    }
                    
                });
            }
        },

        computed:{

            //...mapState(['currentSidePopup']),

        }
    }
</script>

<style scoped>

</style>