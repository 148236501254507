<template>

    
    <transition name="modal">
        <div class="modal-mask"  @click="$emit('close')">
            <div class="modal-wrapper">
                <div class="modal-container">

                    <div class="modal-header">
                        <button class="modal-default-button" @click="$emit('close')">
                            Close
                        </button>
                    </div>

                    <div class="modal-body">
                        <slot name="body">
                            
                        </slot>
                    </div>

                    <div class="modal-footer">
                        <button class="modal-default-button" @click="$emit('close')">
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </transition>
    
</template>

<script>

    export default {

        name: "WeddingItineraryModal",

        props: [],

        data() {
            return {

            }
        },

        mounted() {


        },

        methods: {
            
            
        },
    }
</script>

<style scoped>

    .modal-container {
        height: 100%;
        overflow-y: scroll;
        max-width: 100%;
       
        
    }

    .modal-body {
       padding:0;
        
    }

</style>