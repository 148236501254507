<template>

	<svg viewBox="0 0 85.52 28" class="arrow">
		<path d="M85.52,28H0L42.35,0Z" style="fill: #12100c;fill-rule: evenodd"/>
	</svg>

</template>

<script>

//import { mapState, mapGetters, mapMutations } from 'vuex';

export default {

	name: "GlobalNavigationSubmenuArrow",

}
</script>

<style scoped>

</style>