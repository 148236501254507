import 'promise-polyfill/src/polyfill';
import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

export default new Vuex.Store({

    state: {
        storage:'',
        isMobile: false,
        isTablet: false,
        device:'mobile',

        isIE:false,
        isOldEdge:false,
        is4k:false,
        isSafari:false,

        hasTouch:false,

        navigationRoutes:[],
        currentRoute:null,

        showingModal:false,


        mobileMenuOn:false,
        mobileMenuSubmenuOn:false,


        isScrolledDown: false,
        currentActiveMobileMenu:null,
        sidePopupRequest:'',
        mobileMenuActive:false,

        showWeddingItineraryModal:false,
        showGroupsCateringModal:false,

        showBookingModal:false,

        showingDropdownMenu:false,
    },
    mutations: {
        storeSettings(state,value){
            state.storage = value.storage;
            state.navigationRoutes = value.navigationRoutes;
            state.currentRoute = value.currentRoute;
        },
        storeStorage (state, value) {
            state.storage = value;
        },
        isMobile (state, value) {
            state.isMobile = value;
        },
        isTablet (state, value) {
            state.isTablet = value;
        },
        setDevice (state, value) {
            state.device = value;
        },
        storeHasTouch(state,value){
            state.hasTouch = value;
        },
        storeIsIE(state,value){
            state.isIE = value;
        },
        storeIsOldEdge(state,value){
            state.isOldEdge = value;
        },
        storeIs4k(state,value){
            state.is4k = value;
        },

        storeIsSafari(state,value){
            state.isSafari = value;
        },
        storeShowingDropdownMenu(state,value){
            state.showingDropdownMenu = value;
        },

        storeShowingModal (state, value) {
            state.showingModal = value;
            console.log('storeShowingModal')
            if( value ){
                document.querySelector('body').classList.add('show-modal');

            }else{
                document.querySelector('body').classList.remove('show-modal');
            }
        },
        showWeddingItineraryModal (state, value) {
            state.showWeddingItineraryModal = value;
            state.showingModal = value;
        },
        showGroupsCateringModal (state, value) {
            state.showGroupsCateringModal = value;
            state.showingModal = value;
        },

        setMobileNavMenu (state, value) {
            state.mobileMenuOn = !state.mobileMenuOn;
        },

        setMobileNavSubmenuOn (state, value) {
            state.mobileMenuSubmenuOn = value;
        },

        isScrolledDown (state, value) {
            state.isScrolledDown = value;
        },
        storeCurrentActiveMobileMenu (state, value) {
            state.currentActiveMobileMenu = value;
        },
        storeSidePopupRequest (state, value){
            state.sidePopupRequest = value;
        },
        storeMobileMenuActive(state,value){
            state.mobileMenuActive = value;
        },
        storeShowBookingModal ( state, value ) {
            console.log('storeShowBookingModal')
            state.showBookingModal = value;

        },
    }


});
