<template>

	<div class="progressBar">

		<div class="bar" :style="{width:progress + '%'}"></div>

	</div>

</template>

<script>

//import { mapState, mapGetters, mapMutations } from 'vuex';

export default {

	name: "HeroSlideNavProgress",

	props: {
		progress: Number,
	},

	data() {
		return {

		}
	},

	mounted() {


	},

	methods: {

		//...mapMutations(['isMobile']),


	},

	computed: {

		//...mapState(['locale','storage','isMobile']),


	},

	watch: {},
}
</script>

<style scoped lang="scss">

//@import "~bootstrap/scss/functions";
//@import "~bootstrap/scss/variables";
//@import "~bootstrap/scss/mixins";
//@import "./../../../../sass/_variablesB4.scss";

	.progressBar{
		height:0.125rem;
		width:100%;
		background-color:#e0dfdf;
		overflow:hidden;

		.bar{
			height:0.125rem;
			background-color:#62605d;
			transition:width 0.1s ease;
		}
	}

</style>