<template>
    
    <div class="mobile-menu offcanvas-toggle" :class="{'active':currentActiveMobileMenu == this.panelId}">


        <div class="close-menu" v-touch:tap.prevent="handleClose">
            <font-awesome-icon :icon="['fal', 'angle-left']"  full-width></font-awesome-icon> Back
        </div>
        
        <slot></slot>
        
    </div>
    
</template>

<script>
    
    import { mapState, mapMutations } from 'vuex';

    export default {

        name: "global-navigation-mobile-panel",
	    components: {},
	    props: [
            'panelId'
        ],

        data() {
            return {}
        },

        mounted() {


        },

        methods: {
            ...mapMutations(['storeCurrentActiveMobileMenu']),
            
        
            handleClose(e){
                this.storeCurrentActiveMobileMenu(null)
            }
            
        },

        computed:{

            ...mapState(['isMobile','currentActiveMobileMenu']),

        }
    }
</script>

<style scoped>

</style>