<template>

    <div id="ActivityEngine">

        
        
        <div id="ActivityFilter" class="">

                    <div class="form-group">
                        <label for="seasons">Filter by Season</label>
                        <select name="seasons" id="seasons" class="form-control" @change="handleSeasonChange($event)">
                            <option v-for="(season, index) in seasons" 
                                    :value="index" :key="index"
                                    :selected="index == selectedSeason">
                                {{ season }}
                            </option>
                        </select>
                    </div>

                    <div class="form-group">
                        <label for="categories">Filter by Category</label>
                        <select name="categories" id="categories" class="form-control" @change="handleCatChange($event)">
                            <option v-for="(category, index) in categories" 
                                    :value="index" :key="index"
                                    :selected="index == selectedCategory"
                                    >
                                {{ category }}
                            </option>
                        </select>
                    </div>

                    <div class="form-group">
                        <label for="ageGroup">Filter by Appropriate Age</label>
                        <select name="ageGroup" id="ageGroup" class="form-control" @change="handleAgeChange($event)">
                            <option v-for="(ageGroup, index) in ageGroups" 
                                    :value="index" :key="index"
                                    :selected="index == selectedAge">
                                {{ ageGroup }}
                            </option>
                        </select>
                    </div>
        
                    <div class="reset form-group">
                        <label for="ResetBtn">&nbsp;</label>
                        <button class="btn" id="ResetBtn" @click="reset">Reset</button>
                    </div>

        </div>
        
        <div id="ActivityResults" class="container-fluid">
            <div class="row justify-content-center">
                <div class="empty" v-if="activities.length === 0">
                    <font-awesome-icon :icon="['fas', 'exclamation-triangle']"  full-width></font-awesome-icon>
                    <!--<i class="fas fa-exclamation-triangle"></i>-->
                    
                    Your selection produced no results. Please adjust the filters above to see additional options.
                
                </div>

                <div class="results">

                    <activity-bin  v-for="activity in activities" :key="activity.id" :data="activity"></activity-bin>
                    
                </div>
                
            </div>
        </div>
        
    </div>
    
</template>

<script>
    
    import ActivityBin from "./ActivityBin";
    
    export default {

        name: "ActivityFilter",

        components:{
            'ActivityBin':ActivityBin
        },
        
        props: [
            'seasons',
            'seasonPaths',
            'ageGroups',
            'ageGroupPaths',
            'initialSeason',
            'initialCategory',
            'initialAge',
        ],

        
        data() {
            return {
                activities:[],
                selectedSeason:1,
                selectedCategory:1,
                selectedAge:1,
                categories:[],
                categoryPaths:[],
            }
        },

        mounted() {
            //console.log('filter mounted');
            this.selectedSeason = this.initialSeason;
            this.selectedCategory = this.initialCategory;
            this.selectedAge = this.initialAge;
            
            
            this.callForActivities();
        },

        methods: {
            callForActivities(){
                axios.get( '/api/activities/' + this.selectedSeason + "/" + this.selectedCategory + "/" + this.selectedAge )
                    .then( response => {
                        //console.log(response.data);

                        this.activities = response.data.activities;
                        this.categories = response.data.categories;
                        this.categoryPaths = response.data.categoryPaths;
                       
                    })
                    .catch(error => {
                        console.log('error.response', error );
                    });
            },
            handleSeasonChange(e){
                this.selectedSeason = parseInt( e.target.value );
                this.callForActivities();
                this.path();
                //window.history.replaceState({}, document.title, window.location.pathname + '?category=' + this.sort + '&mode=' + newValue);



            },
            handleCatChange(e){
                this.selectedCategory = parseInt( e.target.value );
                this.callForActivities();
                this.path();
            },
            handleAgeChange(e){
                this.selectedAge = parseInt( e.target.value );
                this.callForActivities();
                this.path();
            },
            
            reset(){
                this.selectedSeason = 1;
                this.selectedCategory = 1;
                this.selectedAge = 1;
                this.callForActivities();
                this.path();
            },
            path(){

                let a = location.origin  + '/activities';
                let p = '';

                if(this.selectedSeason + this.selectedCategory + this.selectedAge === 3){
                    history.replaceState(null, null, p + '/activities#ActivityEngine');
                    return p + '#ActivityEngine';
                }

                if(this.selectedSeason>1){
                    p += '?season=' + this.seasonPaths[this.selectedSeason];
                }

                if(this.selectedCategory>1){
                    p += this.selectedSeason>1 ? '&' : '?';
                    p += 'category=' + this.categoryPaths[this.selectedCategory];
                }

                if(this.selectedAge>1){
                    p += this.selectedSeason + this.selectedCategory > 0 ? '&' : '?';
                    p += 'age=' + this.ageGroupPaths[this.selectedAge];
                }

                history.replaceState(null, null, p + '#ActivityEngine');

                return p + '#ActivityEngine';
            }
        },
        
        computed: {
            
        }
    }
</script>

<style scoped>

</style>