<template>
    <div class="showable" :class="(show ? 'show ':' ') + cssClass">

        <div v-if="isMobile"
             class="embed-responsive d-md-none" :style="{ paddingBottom: mobilePadding + '%' }">
            <video :src="mobile"  ref="Video" width="640" height="480" autoplay muted loop playsinline></video>
        </div>
        <div v-else
             class="embed-responsive d-none d-md-block" :style="{ paddingBottom: desktopPadding + '%' }">
            <video :src="desktop" ref="Video" width="1920" height="1080" autoplay muted loop playsinline></video>
        </div>

	    <div class="showable" :class="{'show':show}">
            <slot></slot>
	    </div>

    </div>
</template>

<script>

    import { mapState, mapMutations } from 'vuex';

    export default { 

        name: "Html5ResponsiveVideo",

        props: {
            desktop: {
                type: String,
                required: false
            },
            mobile: {
                type: String,
                default: false
            },
            mobileWidth: {
                type: Number,
                default: null
            },
            mobileHeight: {
                type: Number,
                default: null
            },
            desktopWidth: {
                type: Number,
                default: null
            },
            desktopHeight: {
                type: Number,
                default: null
            },
            autoPause: {
                type: Boolean,
                default: true
            },
            cssClass: {
                type: String,
                required: false
            },
        },
        
        data() {
            return {
	            show:false
            }
        },

        mounted() {

            //console.log('is mobile? ', this.isMobile);
	        this.$refs.Video.addEventListener('play', ()=> {
		        this.show = true;
	        });
        },

        methods: {

            //...mapMutations(['isMobile']),


        },

        computed: {

            ...mapState(['locale','storage','isMobile','isTablet']),

            desktopPadding(){
                return (this.desktopHeight / this.desktopWidth) * 100;
            },
            
            mobilePadding(){
                return (this.mobileHeight / this.mobileWidth) * 100;
            }

        },
    }
</script>

<style scoped>
	.showable{
		opacity: 0;
		transition:opacity 1s;
	}
	.showable.show{
		opacity: 1;
	}
	.showable .center-block{
		opacity: 1 !important;
	}
</style>