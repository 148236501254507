<template>
    <div id="Stats" class=" container-fluid" v-vpshow:callback="handleInView">

        <div class="container">

            <div class="row" >

                <div class="col">
                    <h3>By the Numbers</h3>

                    <div class="stats clearfix">

                        <div class="stat">
                            <h4 ref="RanchCountUp" id="RanchCountUp">37,000</h4>
                            <a :href="routeRanch">Acres</a>
                        </div>
                        <div class="stat">
                            <h4 id="HomesCountUp">28</h4>
                            <a :href="routeVacationHomes">Luxury Homes</a>
                        </div>

                        <div class="stat">
                            <h4 id="GlampingCountUp">36</h4>
                            <a :href="routeGlamping">Luxury Tents</a>
                        </div>

                        <div class="stat">
                            <h4 id="BedsCountUp">275</h4>
                            <a :href="routeGroups">Guest Capacity</a>
                        </div>

                        <div class="stat">
                            <h4 id="ActivitiesCountUp">
                                <span id="ActivitiesCountUpEle">50</span>+</h4>
                            <a :href="routeActivities">Activities</a>
                        </div>


                        <div class="stat">
                            <h4 id="DaysCountUp">365</h4>
                            <p>Days</p>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>

    import CountUp from '../../vendor/countup/countUp'
    
    export default {

        name: "StatCounter",

        props: [
            'routeRanch',
            'routeVacationHomes',
            'routeGlamping',
            'routeGroups',
            'routeActivities'
        ],
        components: {
            
        },
        data() {
            return {
                counterRanchEndVal:37000,
                countOptions:{
                    useEasing : true,
                    useGrouping : true,
                    separator : ',',
                    decimal : '.',

                },
                ranchCountOptions: {...this.countOptions,...{startVal:35000}},
                homeCountOptions: {...this.countOptions,...{startVal:1}},
                glampingCountOptions: {...this.countOptions,...{startVal:1}},
                groupsCountOptions: {...this.countOptions,...{startVal:1}},
                activitiesCountOptions: {...this.countOptions,...{startVal:1}},
                daysCountOptions: {...this.countOptions,...{startVal:1}},
            }
        },

        mounted() {

            //console.log('STATS START!');

        },

        methods: {
            handleInView(){
               // console.log('STATS handleInView!');


                const ranchCountUp = new CountUp('RanchCountUp', 37000, this.ranchCountOptions);
                if (!ranchCountUp.error) {
                    ranchCountUp.start();
                } else {
                    console.error(ranchCountUp.error);
                }
                ranchCountUp.start();

                const homeCountUp = new CountUp('HomesCountUp', 28, this.homeCountOptions);
                if (!homeCountUp.error) {
                    homeCountUp.start();
                } else {
                    console.error(homeCountUp.error);
                }
                

                const glampingCountUp = new CountUp('GlampingCountUp', 36, this.glampingCountOptions);
                glampingCountUp.start();

                const groupsCountUp = new CountUp('BedsCountUp', 275, this.groupsCountOptions);
                groupsCountUp.start();

                const activitiesCountUp = new CountUp('ActivitiesCountUpEle', 50, this.activitiesCountOptions);
                activitiesCountUp.start();

                const daysCountUp = new CountUp('DaysCountUp', 365, this.daysCountOptions);
                daysCountUp.start();
            },

        },
    }
</script>

<style scoped>

</style>
