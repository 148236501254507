<template>
    
    <li class="dropdown-navigation" :class="active" @mouseover="handleHover(true)" @mouseleave="handleHover(false)">

	    <a :href="route"
           @click.prevent="handleClick"
           :target="blank ? '_blank':'_self'"
           role="button"
           aria-haspopup="true" :aria-expanded="expanded?'true':'false'">
            <span v-html="label"></span>
	        <global-navigation-submenu-arrow></global-navigation-submenu-arrow>
        </a>

        <slot v-if="!isMobile"></slot>
    </li>
    <!--data-toggle="dropdown" -->
</template>

<script>
    import { mapState, mapMutations } from 'vuex';
    import GlobalNavigationSubmenuArrow from "./GlobalNavigationSubmenuArrow";

    export default {

        name: "global-navigation-link",
	    components: {GlobalNavigationSubmenuArrow},
	    props: [
            'panelId',
            'active',
            'route',
            'label',
            'blank'
        ],

        data() {
            return {
                expanded:false
            }
        },

        mounted() {


        },

        methods: {

            ...mapMutations(['storeCurrentActiveMobileMenu','storeShowingDropdownMenu']),


            handleClick(e){
                //document.getElementById(this.panelId).classList.add('active');

	            //no-touchevents


                if (document.activeElement instanceof HTMLElement)
                    document.activeElement.blur();                
                
                
                if( !this.hasTouch ){
                    
                    document.location = e.target.href;
                    
                }else{
                    //document.getElementById('NavMenuCollapse').scrollTop = 0;
                    
                    this.storeCurrentActiveMobileMenu( this.panelId );
                    
                    //document.getElementById(this.navId).classList.add('active');
                    
                }
                //console.log('click',e.target.href, this.panelId );
            },

            handleHover(e){
            	this.expanded = e;
	            console.log('expanded',e);
	            this.storeShowingDropdownMenu( e );
            }
        },

        computed:{

            ...mapState(['isMobile','hasTouch']),

        },

	    watch:{
		    /*expanded: function(newValue){
		    	console.log('expanded',newValue);
		    }*/
	    }
    }
</script>

<style scoped>

</style>