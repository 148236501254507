<template>
    <div>

        <div class="adventures three-up">

            <a href="#" @click.prevent="showModal = true, currentSeason = springFall, currentModalTitle = 'Spring & Fall Activities'"
               class="link">
                <div class="img-wrap">
                    <img :src="springBg"
                         alt="Activities"
                         class="img-fluid">
                </div>
                <h4>
                    Spring & Fall Activities
                </h4>
            </a>

            <a href="#" @click.prevent="showModal = true, currentSeason = summer, currentModalTitle = 'Summer Activities'"
               class="link">
                <div class="img-wrap">
                    <img :src="summerBg"
                         alt="Activities"
                         class="img-fluid">
                </div>
                <h4>
                    Summer Activities
                </h4>
            </a>

            <a href="#" @click.prevent="showModal = true, currentSeason = winter, currentModalTitle = 'Winter Activities'"
               class="link">
                <div class="img-wrap">
                    <img :src="winterBg"
                         alt="Activities"
                         class="img-fluid">
                </div>
                <h4>
                    Winter Activities
                </h4>
            </a>

        </div>

        <div class="adventure-links">

            <div>
                <a href="/activities/spring-adventures">
                    see all spring adventures <font-awesome-icon :icon="['fal', 'external-link']"  full-width></font-awesome-icon>
                </a>
                
                <a href="/activities/fall-adventures">
                    see all fall adventures <font-awesome-icon :icon="['fal', 'external-link']"  full-width></font-awesome-icon>
                </a>
            </div>
            <div>
                <a href="/activities/summer-activities">see all summer adventures <font-awesome-icon :icon="['fal', 'external-link']"  full-width></font-awesome-icon></a>
            </div>
            <div>
                <a href="/activities/winter-activities">see all winter adventures <font-awesome-icon :icon="['fal', 'external-link']"  full-width></font-awesome-icon></a>
            </div>
        </div>
        
        <group-adventure-modal v-if="showModal" @close="showModal = false">
            <h3 slot="header" style="margin: 0;">
                {{currentModalTitle}}
            </h3>
            <div slot="body">
                <div v-for="item in currentSeason">
                    {{item}}<br/>
                </div>
            </div>
        </group-adventure-modal>
        
    </div>
</template>

<script>
    import GroupAdventureModal from './GroupAdventureModal'
    
    export default {

        name: "GroupAdventures",

        props: [
            'springBg',
            'summerBg',
            'winterBg',
        ],
        
        components: {
            GroupAdventureModal
        },
        
        data() {
            return {
                showModal:false,
                
                currentSeason: null,
                currentModalTitle: 'Adventures',
                
                springFall:[

                    "Archery",
                    "ATV tours",
                    "The Bone Rattler Bike Park",
                    "Canoeing",
                    "Cattle Drive",
                    "Cow Croquet",
                    "Fly-fishing",
                    "Garnet Ghost Town Tour",
                    "Geocaching",
                    "Go-karts",
                    "Grizzlyman Fitness Trail",
                    "Helicopter tours",
                    "Horse-Drawn Carriage Rides",
                    "Horseback Riding",
                    "Hot Air Ballooning",
                    "Mountain Biking",
                    "Nature Hiking",
                    "Old West .22 Rifle Range",
                    "Paintball",
                    "Rappelling",
                    "River Adventures",
                    "The RZR Experience",
                    "The Sky Line Aerial Park",
                    "Sporting Clays",
                    "Team Penning",
                    "Wagon Team Driving",
                ],
                
                summer:[

                    "Archery",
                    "Arena Horseback Lessons",
                    "ATV tours",
                    "The Bone Rattler Bike Park",
                    "Canoeing",
                    "Cattle Drive",
                    "Chuck Wagon Dinner",
                    "Fly-fishing",
                    "Garnet Ghost Town Tour",
                    "Geocaching",
                    "Go-karts",
                    "Grizzlyman Fitness Trail",
                    "Helicopter tours",
                    "Horse-Drawn Carriage Rides",
                    "Horseback Riding",
                    "Hot Air Ballooning",
                    "Mountain Biking",
                    "Nature Hiking",
                    "Old West .22 Rifle Range",
                    "Paintball",
                    "Rappelling",
                    "River Adventures",
                    "The RZR Experience",
                    "The Sky Line Aerial Park",
                    "Sporting Clays",
                    "Tennis",
                    "Wagon Team Driving",

                ],
                
                winter:[

                    "Cow Croquet",
                    "Cross-Country Skiing",
                    "Downhill Skiing",
                    "Dogsledding",
                    "Fat Tire Electric Bike Tours",
                    "Horseback Riding",
                    "Ice Skating",
                    "Skijoring",
                    "Sleigh Rides",
                    "Snow Tubing",
                    "Snowboarding",
                    "Snowmobiling",
                    "Snowshoeing",
                    "Sporting Clays",
                    "Team Penning",
                    "Triathlon",


                ]
            }
        },

        mounted() {


        },

        methods: {},
    }
</script>

<style scoped>

</style>