<template>
    
    <li class="slide" >
        <a :href="slide.link" target="_self">
            <picture v-if="storage">
                <source :srcset="this.slide.image + '.jpg'" media="(min-width: 768px)">

                <source :srcset="this.slide.image + '-m.jpg'" media="(min-width: 10px)">

                <img :src="this.slide.image + '.jpg'" :alt="slide.meta.title"/>
            </picture>

            <div class="meta" :class="slide.meta.class">

                <div class="title" v-html="slide.meta.title"></div>
                <div class="sub-title" v-html="slide.meta.subTitle"></div>

            </div>
        </a>
    </li>
    
</template>

<script>

    import { mapState, mapMutations } from 'vuex';

    export default {

        name: "HeroSlide",

        props: [
            'slide'
        ],

        data() {
            return {}
        },

        mounted() {


        },

        methods: {

            //...mapMutations(['isMobile']),


        },

        computed: {

            ...mapState(['storage','isMobile']),

       
        },
        
        
    }
</script>

<style scoped>

</style>