module.exports = {


    init: function( ) {

        const self = this;
        let updatingHeight = false;

        updateHeroHeight();

        setTimeout(updateHeroHeight,1000);

        $(window).on('resize', updateHeroHeight);


        function updateHeroHeight(){

            if( $(window).width() > 767 && !updatingHeight ){

                $('#SlideChooser').removeClass('enable');

                updatingHeight = true;
                setTimeout(function(){
                    //console.log("clear");
                    updatingHeight = false;
                    var h1 = $('#SubFeatured .top').height() - 7.0;
                    var h2 = $('#SubFeatured .bottom').height();
                    $('#Featured .slides').height(h1 - 5 + h2);
                    $('#SlideChooser').addClass('enable');

                },500);
            }
        }

        $('a.chat').on('click',function(e){
            e.stopPropagation();
            e.preventDefault();
            //console.log('chat');
            LC_API.open_chat_window();

        });

        /*$('img').lazyLoadXT({
            visibleOnly:false,
            checkDuplicates: false
        });*/

        let countInit = false;

        // var parallaxElement = $('#Hero');
        // //var parallaxGlamping = $('#GlampingMod');
        //
        // var animationID;
        // var takeoverheaderHeight = $('.takeover-header').length === 0 ? 0 : $('.takeover-header').outerHeight();
        //
        // function updateScroll(){
        //
        //     var scrolled = $(window).scrollTop() - takeoverheaderHeight;
        //     //var scrolledGlamping = $(window).scrollTop()-1300;
        //
        //     parallaxElement.css({
        //         'transform': 'translate3d(0,' + scrolled * 0.3 + 'px, 0)'
        //     });
        //
        // }

        $(window).on('scroll', function () {

            let windowWidth = $(window).width();

            // if( windowWidth > 767 ){
            //     animationID = window.requestAnimationFrame(updateScroll);
            //
            // }else{
            //     window.cancelAnimationFrame(animationID);
            //     parallaxElement.css({
            //         'transform': ''
            //     });
            // }


            let hT = $('#Stats').offset().top + 40,
                wH = $(window).height(),
                wS = $(this).scrollTop();


            if (wS > (hT - wH) && !countInit) {
                countInit = true;

                var ranchCountUp = new window.CountUp("RanchCountUp", 35000, 37000, 0, 5, {
                    useEasing : true,
                    useGrouping : true,
                    separator : ',',
                    decimal : '.',
                });
                ranchCountUp.start(function() {
                    ranchCountUp.update(37000);
                });
                var homesCountUp = new window.CountUp("HomesCountUp", 1, 28, 0, 5, {useEasing : true, useGrouping : true});
                homesCountUp.start(function() {
                    homesCountUp.update(28);
                });
                var glampingCountUp = new window.CountUp("GlampingCountUp", 1, 36, 0, 5, {useEasing : true, useGrouping : true});
                glampingCountUp.start(function() {
                    glampingCountUp.update(36);
                });
                var bedsCountUp = new window.CountUp("BedsCountUp", 1, 275, 0, 5, {useEasing : true, useGrouping : true});
                bedsCountUp.start(function() {
                    bedsCountUp.update(275);
                });
                var activitiesCountUp = new window.CountUp("ActivitiesCountUpEle", 1, 70, 0, 5, {useEasing : true, useGrouping : true});
                activitiesCountUp.start(function() {
                    activitiesCountUp.update(70);
                });
                var daysCountUp = new window.CountUp("DaysCountUp", 1, 365, 0, 5, {useEasing : true, useGrouping : true});
                daysCountUp.start(function() {
                    daysCountUp.update(365);
                });


            }

        });

        $('#Featured .slides').slick({
            dots:false,
            autoplay: true,
            autoplaySpeed: 6000,
            arrows:false,
            vertical:false,
            verticalSwiping: false,
            fade: true,
            cssEase: 'linear',
            zIndex:1,
            // lazyLoad:'ondemand',
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true,
                        fade: false
                    }
                }
            ]
        });


        $('#Featured .slides').on('afterChange', function(event, slick, currentSlide, nextSlide){
            $('#SlideChooser').find('a').removeClass('active').eq(currentSlide).addClass('active');
        });

        $('#SlideChooser').find('a').on('click',function(e){
            e.preventDefault();
            //console.log($(this).index());
            $('#SlideChooser').find('a').removeClass('active').eq($(this).index()).addClass('active');
            $('#Featured .slides').slick('slickGoTo',$(this).index());
        });


        $('#SubFeatured .top .slides').slick({
            dots:true,
            autoplay: true,
            autoplaySpeed: 10000,
            arrows:false,
            vertical:false,
            verticalSwiping: false,
            fade: true,
            cssEase: 'ease',
            zIndex:1,
            speed:150,
            //lazyLoad:'ondemand',
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true,
                        fade: false
                    }
                }
            ]
        });

       /* $('#SubFeatured .bottom .slides').slick({
            dots:true,
            autoplay: true,
            autoplaySpeed: 8000,
            arrows:false,
            vertical:false,
            verticalSwiping: false,
            fade: true,
            cssEase: 'ease',
            zIndex:1,
            speed:150,
            // lazyLoad:'ondemand',
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true,
                        fade: false
                    }
                }
            ]
        });*/

        /*$('.activity-gallery').slick({
            dots:true,
            autoplay: false,
            arrows:true,
            cssEase: 'ease',
            zIndex:1,
            slidesToShow: 4,
            slidesToScroll: 4,
            infinite: false,
            //variableWidth: true
            //lazyLoad:'progressive',
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true,
                        fade: false,
                        arrows:false,
                    }
                }
            ]
        }).on('afterChange', function(event, slick, currentSlide){
            /!*if( currentSlide > 2 ){
                $('#ActivitySliderTitle').html('Featured Winter Adventures');
            }else{
                $('#ActivitySliderTitle').html('Featured Fall Adventures');
            }*!/
            /!*$('.activityBin img').lazyLoadXT({
                visibleOnly:false,
                checkDuplicates: true
            });*!/
        });*/

        /*$('#TheList .slides').slick({
            dots:true,
            autoplay: true,
            autoplaySpeed: 3000,
            arrows:false,
            vertical:false,
            verticalSwiping: false,
            fade: false,
            cssEase: 'ease',
            zIndex:1,
            speed:150,
            appendDots:$('#TheList .dots'),
            //lazyLoad:'ondemand',
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true,
                        fade: false
                    }
                }
            ]
        }).on('afterChange', function(event, slick, currentSlide){
            
            let slide = $(event.currentTarget).find('.slide').eq(currentSlide+1);
            
           
            
            $('#TheList .caption').html(slide.find('img').attr('alt'));
        });
        */
        
    }



};