

// Initialize the annoying-background directive.
export const EventPopUpLink = {
    
    bind(el, binding, vnode) {

        el.addEventListener("click", event => {
            event.preventDefault();
            //console.log(binding.value || '#');

            vnode.context.$store.commit('storeSidePopupRequest', {
                data: binding.value || '#'
                
            });

        });
        
        
        // Allow users to customise the color by passing an expression.
        //const color = binding.expression || defaultBackgroundColor

        // el might not be present for server-side rendering.
        //if (el) {
            // Set the element's background color.
           // el.style.backgroundColor = color
        //}
        
        
    }
    
}