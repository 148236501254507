<template>

    <div class="">
    

        <slot name="header"></slot>
    
        <v-collapse-wrapper>

        
            <button class="btn plus" v-on:click="clicked" :class="{'active':expand}" v-collapse-toggle>
    
                <span v-html="expand ? 'Close':buttonTitle"></span>
                <!--<i class="fal fa-plus-circle" ></i>-->
                <font-awesome-icon :icon="['fal', 'plus-circle']"  full-width></font-awesome-icon>
            </button>
                
            <div class="expandable" ref="expandable"  v-collapse-content >
                
                <slot name="content"></slot>
                
            </div>
              
        </v-collapse-wrapper>
    

    </div>
    
</template>

<script>

    export default {

        name: "Glance",


        props: [
            'title',
            'subtitle',
            'buttonTitle'
        ],

        data() {
            return {
                expand:false
            }
        },

        mounted() {


        },

        methods: {

            clicked: function(){

                this.expand = !this.expand;
            },

        },

        watch: {
            // expand(value){
            //     this.collapsed = value;
            // }
        }
    }
</script>

<style scoped>

</style>