<template>

	<div ref="bg" class="left-right-content-modules container-fluid border-bottom lazy"
	     :class="{'left':left}"
	     :data-background-image="bg"
	>

		<slot></slot>

	</div>

</template>

<script>

import { mapState, mapGetters, mapMutations } from 'vuex';

export default {

	name: "Accommodation",

	props: {
		left: {
			type:Boolean,
			default:false
		},
		img: {
			type:String,
			default:null
		}
	},

	data() {
		return {
			bg:null
		}
	},

	mounted() {

		/*this.$refs.bg.addEventListener('lazybeforeunveil',()=>{

		});*/
	},

	methods: {

		//...mapMutations(['isMobile']),

		checkDevice(val){
			switch(val){
				case('mobile'):{
					this.bg = this.storage + '/home/' + this.img + '-m.jpg';
					break;
				}
				case('tablet'):{
					this.bg = this.storage + '/home/' + this.img + '-t.jpg';
					break;
				}
				default:
					this.bg = this.storage + '/home/' + this.img + '.jpg';
			}
			this.$refs.bg.style.backgroundImage = 'url(' + this.bg + ')';
		}

	},

	computed: {

		...mapState(['storage','isMobile','isTablet','device']),


	},

	watch: {
		storage:function(val){
			this.checkDevice(this.device);
		},
		device:function(val){
			this.checkDevice(val);
		},

		/*isMobile:function(val){
			if( val ){
				this.bg = this.storage + 'home/' + this.img + '-m.jpg';
			}
			}else if( this.isTablet ){
				this.bg = this.storage + 'home/' + this.img + '-t.jpg';
			}else{
				this.bg = this.storage + 'home/' + this.img + '.jpg';
			}
		},
		isTablet:function(val){
			if( val ){
				this.bg = this.storage + 'home/' + this.img + '-t.jpg';
			}else if( this.isMobile ){
				this.bg = this.storage + 'home/' + this.img + '-m.jpg';
			}else{
				this.bg = this.storage + 'home/' + this.img + '.jpg';
			}
		}*/
	},
}
</script>

<style scoped>

</style>