<template>
    
    
    <div class="position-relative">
    <slick id="AccommodationsSlider" ref="slick" :options="slickOptions">

        <slot></slot>
      
        

    </slick>
      


    </div>
    
    
</template>

<script>
    import Slick from 'vue-slick';
    export default {

        name: "AccomodationsBrowser",

        components: { 'slick': Slick },
        
        props: [],

        data() {
            return {

                slickOptions: {
                    slidesToShow: 1,
                    infinite: true,
                    accessibility: true,
                    adaptiveHeight: false,
                    arrows: false,
                    dots: true,
                    draggable: true,
                    edgeFriction: 0.30,
                    swipe: true,
                    vertical:false,
                    verticalSwiping:false,
                    variableWidth: false,
                    speed:150,
                    autoplay: false,
                    mobileFirst:true,
                    initialSlide:0,
                    centerMode: true,
                    // lazyLoad:'ondemand',

                    responsive: [
                        {
                            breakpoint: 767,
                            settings: {
                                slidesToShow: 2,
                                swipe: true,
                                centerMode: false,
                                arrows: true,
                            }
                        },
                        {
                            breakpoint: 1023,
                            settings: {
                                slidesToShow: 4,
                                swipe: true,
                                centerMode: false,
                                arrows: true,
                            }
                        },
                    ]
                },
                currentIndex:0
            }
        },

        mounted() {


        },

        methods: {},
    }
</script>

<style scoped>

</style>