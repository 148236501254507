<template>
    
    <li class="slide"  :class="slide.css_class">
        <div >

	        <div class="position-relative">
	        <picture>
<!--		        <source :srcset="this.slide.desktop_image"  media="(max-width: 767px)" type="image/jpg" />-->
		        <img class="img-fluid" :src="this.slide.desktop_image" alt="">
	        </picture>

	        <a :href="slide['link-to']" :target="slide.hyperlink_blank?'_blank':'_self'"class="btn link-button" v-show="slide['link-to']">

		        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45.62 52.13">
			        <path d="M45.62,17.11v31a4.08,4.08,0,0,1-4.08,4.07H4.07A4.07,4.07,0,0,1,0,48.06V17.11A4.08,4.08,0,0,1,4.07,13h8.15a.82.82,0,0,1,.81.82.81.81,0,0,1-.81.81H4.07a2.45,2.45,0,0,0-2.44,2.45v31A2.44,2.44,0,0,0,4.07,50.5H41.54A2.45,2.45,0,0,0,44,48.06V17.11a2.45,2.45,0,0,0-2.45-2.45H33.4a.82.82,0,0,1-.82-.81A.82.82,0,0,1,33.4,13h8.14A4.08,4.08,0,0,1,45.62,17.11Zm-32,10.82L22,36.32V.81A.82.82,0,0,1,22.81,0a.81.81,0,0,1,.81.81V36.32L32,27.93a.82.82,0,0,1,1.15,1.16l-9.78,9.77a.81.81,0,0,1-1.15,0l-9.78-9.78a.81.81,0,0,1,0-1.15A.82.82,0,0,1,13.61,27.93Z" style="fill: #fff"/>
		        </svg>


	        </a>
	        </div>
	        <div class="caption d-lg-none">
		        <div v-html="slide.title"></div>
	        </div>

        </div>
    </li>

</template>

<script>

    import { mapState, mapMutations } from 'vuex';

    export default {

        name: "HeroSlideSecondary",

        props: [
            'slide'
        ],

        data() {
            return {}
        },

        mounted() {


        },

        methods: {

            //...mapMutations(['isMobile']),


        },

        computed: {

            ...mapState(['storage','isMobile']),

       
        },
        
        
    }
</script>
<style scoped lang="scss">

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.slide{

	> a{
		display: flex;

	}

	picture {

		@include media-breakpoint-up(lg) {

			width:100%;

		}

	}

	.caption{
		padding:0.5rem 0;
		line-height: 1;
		font-size:0.875rem;
		text-align: center;
	}

	.link-button{

		position: absolute;
		bottom:1rem;
		right:1rem;

		color:#fff;
		background-color:#4d87ff;
		width:60px;
		height:60px;
		border-radius:100%;
		margin-top:1.25rem;
		padding:0.5rem;
		//border: 4px solid white;
		border: none;

		display: flex;
		justify-content: center;
		align-items: center;

		&:hover{
			background-color:#2F6AE3;
		}
		@include media-breakpoint-up(lg) {
			//bottom:1rem;
		}
		@include media-breakpoint-up(xl) {
			margin-top:1.5rem;
		}
		@media (min-width: 1440px) {
			width:78px;
			height:78px;
			margin-top:2rem;
		}

		svg{
			width:24px;

			@media (min-width: 1440px) {
				width:36px;
			}
		}


	}
}

</style>