<template>
    
    <div id="SidePopup" v-show="show" :class="{'initialized':show}">

        <div class="popup-content" v-html="compiledHtml"></div>

        <a class="closePopupBtn" href="#" @click.prevent="closePopup">
            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
                <path id="_" data-name="" class="cls-1-close-btn" d="M15,0.793l-0.8-.8L7.5,6.685,0.8-.01l-0.8.8L6.695,7.488,0,14.183l0.8,0.8L7.5,8.291l6.695,6.695,0.8-.8L8.3,7.488Z"/>
            </svg>

        </a>
        <div class="loadMsg">
            <div class="spinner"></div>
            <div>Loading</div>
        </div>
    </div>
    
</template>

<script>
    import { mapState, mapMutations } from 'vuex';

    export default {

        name: "SidePopup",

        props: [],

        data() {
            return {
                show:false,
                content:''
            }
        },

        mounted() {


        },

        methods: {
            
            //...mapMutations(['currentSidePopup']),
            getPopupHtml: async function( view, id ){

                let self = this;
                
                try {
                    //await api.get();

                    await axios.post('/api/side-popup', {
                        view: view,
                        id: id,
                    })

                        .then( response => {
                            this.show = true;
                            document.getElementsByTagName('body')[0].classList.add('show-side-popup');

                            //document.getElementsByTagName('body')[0].addEventListener('click',this.closePopup);
                            
                            this.content = response.data;

                            
                            document.querySelector('#app > div').addEventListener('click',this.closePopup);
                            
                        })
                        .catch(error => {
                            console.log( "errors",error);
                            // console.log( "errors", error.response.status );
                            //
                            // if (error.response.status === 422) {
                            //
                            //
                            // }else  {
                            //
                            //
                            // }

                        });

                    //commit('updateStatus', 'success');
                } catch (e) {
                    console.error(e);

                    // commit('updateStatus', 'error');
                }
                
            },  
            
            closePopup:function(){

                document.getElementsByTagName('body')[0].classList.remove('show-side-popup');
                
                //document.getElementsByTagName('body')[0].removeEventListener('click',this.closePopup);

                document.querySelector('#app > div').removeEventListener('click',this.closePopup);

                let self = this;
                setTimeout(function(){
                    self.show = false;
                    self.content = '';
                },350);


            }
        },
        
        computed:{

            ...mapState(['sidePopupRequest']),

            compiledHtml: function(){
              
                return this.content;
            }
        },
        
        watch:{
            sidePopupRequest(newValue, oldValue) {
                //console.log(`Updating from ${oldValue} to ${newValue}`);
                //console.log(newValue.data.id);

                if(newValue.data.view || newValue.data.id) {
                    this.getPopupHtml(newValue.data.view, newValue.data.id );
                }
                

            },
            sidePopupContent(newValue, oldValue) {
               // console.log(`Updating from ${oldValue} to ${newValue}`);

                this.content(newValue);

            },
        }
    }
</script>

<style scoped>

</style>