<template>
    
    <div class="wrap-vue-btn">

        <a class="btn " href="#" @click.prevent="$store.commit('showWeddingItineraryModal',true)">
            <!--<i class="fas fa-fw fa-file"></i>-->
            <font-awesome-icon :icon="['fas', 'file']"  full-width></font-awesome-icon>
            See Sample Itenerary
        </a>



        
    </div>
    
</template>

<script>
    import WeddingItineraryModal from './WeddingItineraryModal'
    
    export default {

        name: "WeddingItineraryButton",

        props: [],
        
        components: {
            WeddingItineraryModal
        },
        
        data() {
            return {
                showModal:false,
            }
        },

        mounted() {


        },

        methods: {},
    }
</script>

<style scoped>

</style>