<template>

	<div class="camp-buyout-accordion overview-slice tents">
	
		<div class="title-slice meta">
			<h2>Which luxury camp is best for your group?</h2>
		</div>
		
		<v-collapse-group :onlyOneActive="true">
			
			<v-collapse-wrapper
                v-for="(camp,key,index) in camps"
                :key="key" :camp="camp"
                class="expandable"
                :class="{'active':camp.index === currentExpandedIndex}"
				:ref="'wrap'+index"
                @afterToggle="afterToggle">
				
				<div class="top" v-collapse-toggle >
					
					<div class="meta">

						<div class="titles">
	
							<h5 v-html="camp.title"></h5>
	
							<h2 v-html="camp.subtitle"></h2>
	
						</div>
	
						<div class="info">
	
							<div class="btn plus" :class="{'active':expand}" >
								<span v-html="camp.index === currentExpandedIndex ? 'Close':'Details'"></span>
								<font-awesome-icon :icon="['fal', 'plus-circle']"  full-width></font-awesome-icon>
							</div>
	
						</div>

					</div>
					
				</div>

				<div v-collapse-content >

					<div class="padding-box">
						
						<div class="content">
	
							<picture>
								<source :srcset="camp.imageRoot + '.jpg'" media="(min-width: 768px)">
								<source :srcset="camp.imageRoot + '-m.jpg'" media="(min-width: 10px)">
								<img :srcset="camp.imageRoot + '.jpg'" alt="" class="img-fluid">
							</picture>

							<div class="centered-content">
							<div class="meta-window " :class="camp.windowClass">

								<h5  v-html="camp.subtitle"></h5>
								<h2 v-html="camp.title"></h2>
								<p v-html="camp.copy"></p>

								<p>
									Maximum Occupancy: <span v-html="camp.maxOccupancy"></span>
								</p>


								<a class="btn" :href="camp.route">
									{{camp.cta}}
									<font-awesome-icon :icon="['fal', 'arrow-circle-right']"  full-width></font-awesome-icon>
								</a>


							</div>
							</div>
						</div>
							
					</div>
					
				</div>

			</v-collapse-wrapper>
			
		</v-collapse-group>

	</div>
      
</template>

<script>
    
	import ReserveCampSlice from "./ReserveCampSlice";

    export default {

        name: "ReserveCampAccordion",

        components:{
	        ReserveCampSlice
        },

        props: [
            'camps',

        ],

        data() {
            return {
                expand:false,
                computerButtonTitle:'',
	            currentExpandedIndex:null,
            }
        },

        mounted() {
        
            this.computerButtonTitle = this.buttonTitle;

        },

        methods: {

	        handleExpanded: function(e){
                console.log('expanded',e);
                //this.currentExpanded = e;
            },

	        afterToggle:function(e){

		        if( e.$attrs.camp.index === this.currentExpandedIndex ){
			        this.currentExpandedIndex = null;
		        }else{
			        this.currentExpandedIndex = e.$attrs.camp.index;
		        }
		        
	        },

        },

        watch: {
            expand(value) {
                if(value){
                    this.computerButtonTitle = 'Close';
                }else{
                  this.computerButtonTitle =  this.buttonTitle;
                }
            }
        }
    }
</script>

<style scoped>

</style>