<template>

	<div class="hero-slider-nav" ref="SlideChooser">

		<a href="#" class="choose"
		   v-if="!singleMode"
		   v-for="(item, index) in slideGroup.slides"
		   :data-id="index"
		   :class="{'active':currentSlide === (index+1)}"
		   @click.prevent="$emit('change', index)"
		  >
			<hero-slide-nav-progress :progress="currentSlide === (index+1) ? progress : 0"></hero-slide-nav-progress>
			<div class="name"  v-html="item.category_title"></div>
		</a>

		<div v-if="singleMode"  class="choose active">
			<hero-slide-nav-progress v-if="slideGroup.slides.length > 1" :progress="progress"></hero-slide-nav-progress>
			<div class="name"  v-html="slideGroup.slides[currentSlide-1].title"></div>
		</div>

	</div>


</template>

<script>

//import { mapState, mapGetters, mapMutations } from 'vuex';

import HeroSlideNavProgress from "./HeroSlideNavProgress";
export default {

	name: "HeroSliderNav",
	components: {HeroSlideNavProgress},
	props: {
		slideGroup: {
			type: Array|Object,
			default:[]
		},
		singleMode: {
			type:Boolean,
			default:false
		},
		currentSlide: {
			type:Number,
			default:0
		},
		progress: {
			type:Number,
			default:0
		}
	},

	data() {
		return {
			current:null,
		}
	},

	mounted() {


	},

	methods: {

		//...mapMutations(['isMobile']),


	},

	computed: {

		//...mapState(['locale','storage','isMobile']),


	},

	watch: {},
}
</script>

<style scoped lang="scss">

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "./../../../../sass/_variablesB4.scss";

.hero-slider-nav{

	display:flex;

	> a, > div {

		display:block;
		flex-grow:1;


		margin-right: 1px;
		//border-top:4px solid rgba(0,0,0,0);

		transition:all 0.5s ease;
		background:linear-gradient(180deg, rgba(241, 241, 241, 1) 0%, rgba(241, 241, 241, 0) 100%);

		.name{
			text-align: center;
			font-size: 0.6875rem;
			text-transform: uppercase;
			color:#62605d;
			font-weight: 700;
			padding:0.5rem 0;
			transition:color 0.2s;
		}

		&.active{
			border-color:$brand-gold;
			background:lighten($body-bg,10%);
		}

		&:hover{

			.name{
				color:#2F6AE3;
			}
		}
	}


}

</style>