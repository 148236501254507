
<template>
	<transition name="fade">
		<div class="modal-2" v-if="show" aria-modal="true" :class="customClass">
			<div class="modal-2__backdrop" aria-label="close modal" @click="storeShowBookingModal(false)"/>

			<div class="modal-2__dialog" >
				<div class="modal-2__header" v-if="showHeader">
					<slot name="header"/>

				</div>

				<div class="modal-2__body">
					<slot name="body"/>
				</div>

				<div class="modal-2__footer"  v-if="showFooter">
					<slot name="footer"/>
				</div>

				<button type="button"  aria-label="close modal"  class="modal-2__close  close-btn btn" @click="storeShowBookingModal(false)">
					<font-awesome-icon :icon="['fal', 'times']"  full-width></font-awesome-icon>
				</button>
			</div>
		</div>
	</transition>
</template>

<script>

import { mapState, mapMutations } from 'vuex';

export default {

	name: "Modal",
	props:{
		customClass: {
			type:String,
		},
		showHeader: {
			type:Boolean,
			default: false
		},
		showFooter: {
			type:Boolean,
			default: false
		}
	},

	data() {
		return {
			show: false
		};
	},
	methods: {
		...mapMutations(['storeShowBookingModal']),

		closeModal() {
			this.show = false;
			document.querySelector("body").classList.remove("overflow-hidden");
		},
		openModal() {
			this.show = true;
			document.querySelector("body").classList.add("overflow-hidden");
		},
		escapeKey(e){
			if(e.key === 'Escape') this.storeShowBookingModal(false);
		}
	},

	computed: {
		...mapState(['showBookingModal']),

	},

	watch: {
		showBookingModal: function (newValue) {
			console.log('-----',newValue);
			this.show = newValue;
			if( newValue ){
				document.querySelector('body').classList.add('show-modal');
				document.addEventListener('keydown', this.escapeKey);
			}else{
				document.querySelector('body').classList.remove('show-modal');
				document.removeEventListener('keydown', this.escapeKey);
			}
		}
	}
};
</script>


<style lang="scss" scoped>
.modal-2 {
	overflow-x: hidden;
	overflow-y: auto;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 9;
	justify-content: center;
	align-items: center;
	display: flex;
	&__backdrop {
		background-color: rgba(0, 0, 0, 0.8);
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 1;
	}
	&__dialog {
		background-color: #ffffff;
		position: relative;
		width: 800px;
		margin: 50px auto;
		display: flex;
		flex-direction: column;
		border-radius: 5px;
		z-index: 2;
		@media screen and (max-width: 992px) {
			width: 90%;
		}
	}
	&__close {

		position: absolute;
		top:5px;
		right:5px;
		font-size: 26px;
		display: flex;
		align-content: center;
		justify-content: center;
	}
	&__header {
		padding: 20px 20px 10px;
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
	}
	&__body {
		padding: 0;
		overflow: auto;
		display: flex;
		flex-direction: column;
		align-items: stretch;
	}
	&__footer {
		padding: 10px 20px 20px;
	}
}
.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
	opacity: 0;
}
</style>
view rawModal.vue hosted with ❤ by GitHub