<template>

    <transition name="modal">
        <div class="modal-mask " @click="$emit('close')">
            <div class="modal-wrapper">
                <div class="modal-container capacity-modal" style="max-width: 300px;">
                    <button class="close-btn btn" @click="$emit('close')">
                        <font-awesome-icon :icon="['fal', 'times']"  full-width></font-awesome-icon>
                    </button>
                    <div class="modal-header">
                        <h2>{{ data.location }}</h2>
                        <h3>{{ data.name }}</h3>
                    </div>

                    <div class="modal-body">
                        <div class="stat" v-if="data.description">
                            <div class="desc" v-html="data.description"></div>
                        </div>
                        


                        <div class="stat" v-if="data.meetingCapacity">
                            <div class="lbl">Meeting Capacity:</div>
                            <div class="value" v-html="data.meetingCapacity"></div>
                        </div>
                        <div class="stat" v-if="data.receptionCapacity">
                            <div class="lbl">Reception Capacity:</div>
                            <div class="value" v-html="data.receptionCapacity"></div>
                        </div>
                        <div class="stat" v-if="data.diningCapacity">
                            <div class="lbl">Dining Capacity:</div>
                            <div class="value" v-html="data.diningCapacity"></div>
                        </div>
                        <div class="stat" v-if="data.size">
                            <div class="lbl">Size:</div>
                            <div class="value" v-html="data.size"></div>
                        </div>
                        <div class="stat" v-if="data.extSize">
                            <div class="lbl">Outdoor Size:</div>
                            <div class="value" v-html="data.extSize ? data.extSize : '<span>...</span>'"></div>
                        </div>
                        <div class="stat" v-if="data.open">
                            <div class="lbl">Availabilty:</div>
                            <div class="value" v-html="data.open"></div>
                        </div>
                    </div>

<!--                    <div class="modal-footer">-->
<!--                        <button class="modal-default-button" @click="$emit('close')">-->
<!--                            Close-->
<!--                        </button>-->
<!--                    </div>-->
                </div>
            </div>
        </div>
    </transition>

</template>

<script>

    export default {

        name: "GroupAdventureModal",

        props: [
            'data'
        ],

        data() {
            return {

            }
        },

        mounted() {


        },

        methods: {


        },
    }
</script>

<style scoped>



</style>