<template>
    <div :class="stickyClass" :style="stickyStyle">
        <slot></slot>
    </div>
</template>

<script>
    import { mapState, mapMutations } from 'vuex';

    export default {

        name: "StickyTop",

        props: ['top', 'scrollY'],

        data() {
            return {
                stickyStyle: {},
                stickyClass: '',
                originalTop: 0 
            }
        },

        mounted() {

            
            //this.originalTop = this.$el.getBoundingClientRect().top;
            
        },

        methods: {
            ...mapMutations(['isScrolledDown']),

        },

        watch: {
            scrollY(newValue) {
                
                if( this.isMobile ){
                    this.stickyClass = '';
                    this.isScrolledDown(false);
                }else{
                    //const rect = this.$el.getBoundingClientRect();
                    //const newTop = this.scrollY + +this.top - this.originalTop;

                    
                    if (this.scrollY > 0) {
                        //this.$set(this.stickyStyle, 'top', `${newTop}px`);
                        //this.$set(this.stickyStyle, 'top', `${newTop}px`);
                        this.stickyClass = 'sticky';
                        this.isScrolledDown(true);
                    } else {
                        //this.$delete(this.stickyStyle, 'top');
                        this.stickyClass = '';
                        this.isScrolledDown(false);
                    }

                }

            }
        },

        computed:{

            ...mapState(['isMobile']),

        }
    }
</script>

<style scoped>

</style>