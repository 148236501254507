<template>

    
    <transition name="modal">
        <div class="modal-mask modal-full-screen"  @click="$emit('close')">
            <div class="modal-wrapper">
                <div class="modal-container" style="overflow-y: scroll;">

                    <div class="modal-header" style="padding: 15px;">
                        <button class="modal-default-button" @click="$emit('close')">
                            Close
                        </button>
                    </div>

                    <div class="modal-body" style="padding:0;width:auto">
                        <slot name="body">
                            
                        </slot>
                    </div>

                    <div class="modal-footer">
                        <button class="modal-default-button" @click="$emit('close')">
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </transition>
    
</template>

<script>

    export default {

        name: "WeddingItineraryModal",

        props: [],

        data() {
            return {

            }
        },

        mounted() {


        },

        methods: {
            
            
        },
    }
</script>

<style scoped>

</style>