<template>

    <div class="">
        
        <v-collapse-wrapper>

            <div class="top" :class="topClass">
                
                <slot name="header"></slot>
  
                <button class="gallery btn" @click="showModal = true" v-if="showGallery">
                    View gallery
                    <font-awesome-icon :icon="['fal', 'expand']"  full-width></font-awesome-icon>
                    <!--<i class="fal fa-expand"></i>-->
                        
                    
                </button>
    
                <div class="meta" v-collapse-toggle v-on:click="clicked" >
                    
                    <div class="titles" v-show="title || subtitle || bathrooms">
                        <h2 v-html="title"></h2>
                        
                        <div class="data" v-if="subtitle" v-html="subtitle"></div>
    
                        <div class="data" v-if="!subtitle && bathrooms" >
                            {{bathrooms}}
                            <font-awesome-icon :icon="['fas', 'circle']"  full-width></font-awesome-icon>
                            <!--<i class="fas fa-circle"></i> -->
                            {{guests}}
                        </div>
                        
                    </div>
                    
                    <div class="info" >
                        
                        <div class="btn plus" :class="{'active':expand, 'light':!dark}"  >
                            
                            <span :style="{'fontSize':buttonTextSize}" v-html="buttonTitle"></span>

                     
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45 45" width="45" height="45" class="svg-btn-plus">
                                <g  :style="{'fill':buttonColor}"> 
                                    <g class="ring">
                                        <path d="M22.5,3A19.5,19.5,0,1,1,3,22.5,19.53,19.53,0,0,1,22.5,3m0-3A22.5,22.5,0,1,0,45,22.5,22.5,22.5,0,0,0,22.5,0Z" />
                                    </g>
                                    
                                    <g class="inner">
                                        <g class="west">
                                            <path d="M20.5,21h-9a1.5,1.5,0,0,0,0,3h9a1.5,1.5,0,0,0,0-3Z"/>
                                        </g>
                                        <g class="east">
                                            <path d="M33.5,21h-9a1.5,1.5,0,0,0,0,3h9a1.5,1.5,0,0,0,0-3Z"/>
                                        </g>
                                        <g class="north">
                                            <path d="M22.5,10A1.5,1.5,0,0,0,21,11.5v9a1.5,1.5,0,0,0,3,0v-9A1.5,1.5,0,0,0,22.5,10Z"/>
                                        </g>
                                        <g class="south">
                                            <path d="M22.5,23A1.5,1.5,0,0,0,21,24.5v9a1.5,1.5,0,0,0,3,0v-9A1.5,1.5,0,0,0,22.5,23Z"/>
                                        </g>
                                    </g>
                                    
                                    <g class="pivot">
                                        <path d="M22.5,21A1.5,1.5,0,1,0,24,22.5,1.5,1.5,0,0,0,22.5,21Z"/>
                                    </g>
                                </g>
                            </svg>

                        </div>
                        
                    </div>
                    
                </div>
                
            </div>
            
                
            <div class="expandable" :class="contentClass" ref="expandable"  v-collapse-content >
                
                <slot name="content"></slot>
                
            </div>
          
        </v-collapse-wrapper>

        <gallery-modal
                :data="gallery"
                :storage="storage"
                v-if="showModal && showGallery" @close="closeModal"
        >

        </gallery-modal>
        
    </div>
    
</template>

<script>
    
    import GalleryModal from './../shared/GalleryModal'

    export default {

        name: "Expando",

        components:{
            GalleryModal
        },

        props: {
            'title':String,
            'subtitle':String,
            'bathrooms':String,
            'guests':String,
            'gallery':Boolean,
            'storage':String,
            'contentClass':String,
            'topClass':String,
            'showGallery':Boolean,
            'buttonTitle':String,
            'buttonColor':{
                type:String,
                default:'#fff'
            },
            'buttonTextSize':{
                type:String,
                default:'12px'
            },
	        'dark':Boolean,
        },

        data() {
            return {
                expand:false,
                showModal:false,
            }
        },

        mounted() {


        },

        methods: {

            clicked: function(){
                this.expand = !this.expand;
            },
            closeModal: function(){
                this.showModal = false;
                this.$store.commit('storeShowingModal',false);
            }
        },

        watch: {
            // expand(value){
            //     this.collapsed = value;
            // }
        }
    }
</script>

<style scoped>

</style>