<template>

	<div class="hero-slider-component">
		<div class="hero-slider">

			<div class="featured"
			     @mouseover="clearProgress()"
			     @mouseout="startProgressTimer()"
			>

				<slick ref="slick" class="slides"
				       :options="slickOptions"
				       @init="handleInit"
				       @afterChange="handleAfterChange">

					<hero-slide
						v-for="(slide,index) in primarySlideGroup.slides" :key="index"
						:slide="slide"
					></hero-slide>

				</slick>

			</div>

			<div class="sub-featured"
			     @mouseover="clearSecondaryProgress()"
			     @mouseout="startSecondaryProgressTimer()"
			>

				<slick ref="slickSecondary" class="slides"
				       :options="slickSecondaryOptions"
				       @init="handleSecondaryInit"
				       @afterChange="handleAfterChangeSecondary">

					<hero-slide-secondary
						v-for="(slide,index) in secondarySlideGroup.slides" :key="index"
						:slide="slide"
					></hero-slide-secondary>

				</slick>

			</div>

		</div>

		<div class="controls d-none d-lg-flex">

			<hero-slider-nav
				@change="changeSlide"
				:progress="progress"
				:current-slide="currentSlide"
				:slide-group="primarySlideGroup"></hero-slider-nav>

			<hero-slider-nav
				:single-mode="true"
				@change="changeSecondarySlide"
				:progress="progressSecondary"
				:current-slide="currentSecondarySlide"
				:slide-group="secondarySlideGroup"></hero-slider-nav>
		</div>

	</div>

</template>

<script>

import { mapState, mapGetters, mapMutations } from 'vuex';
import Slick from 'vue-slick';
import HeroSlide from "./HeroSlide";
import HeroSlideSecondary from "./HeroSlideSecondary";
import HeroSliderNav from "./HeroSliderNav";

export default {

	name: "HeroSlider",

	components: {HeroSliderNav, HeroSlide, HeroSlideSecondary, Slick },

	props: {
		primarySlideGroup: Object,
		secondarySlideGroup: Object,
	},

	data() {
		return {
			currentSlide:1,
			progress:0,
			progressInterval:null,
			progressDuration:50,
			progressIncrement:0,

			currentSecondarySlide:1,
			progressSecondary:0,
			progressSecondaryInterval:null,

			slickOptions: {
				dots:false,
				autoplay: true,
				autoplaySpeed: 6000,
				arrows:false,
				vertical:false,
				verticalSwiping: false,
				fade: true,
				cssEase: 'linear',
				zIndex:1,
				infinite: true,
				// lazyLoad:'ondemand',
				responsive: [
					{
						breakpoint: 768,
						settings: {
							slidesToShow: 1,
							slidesToScroll: 1,
							infinite: true,
							dots: true,
							fade: true
						}
					}
				]
			},
			slickSecondaryOptions: {
				dots:false,
				autoplay: true,
				autoplaySpeed: 6000,
				arrows:false,
				vertical:false,
				verticalSwiping: false,
				fade: true,
				cssEase: 'linear',
				zIndex:1,
				infinite: true,
				// lazyLoad:'ondemand',
				responsive: [
					{
						breakpoint: 992,
						/*settings: {
							slidesToShow: 1,
							slidesToScroll: 1,
							infinite: true,
							dots: true,
							fade: true
						}*/
						settings: "unslick"
					}
				]
			},
		}
	},

	mounted() {


		this.calculateProgressIncrement();

	},

	methods: {

		//...mapMutations(['isMobile']),

		handleInit(event,slick){
			this.startProgressTimer();
		},

		handleSecondaryInit(event,slick){
			this.startSecondaryProgressTimer();
		},

		handleAfterChange(event, slick, currentSlide) {
			this.currentSlide = this.$refs.slick.currentSlide() + 1;
			this.clearProgress();
			this.startProgressTimer();
		},

		handleAfterChangeSecondary(event, slick, currentSlide) {
			this.currentSecondarySlide = this.$refs.slickSecondary.currentSlide() + 1;
			this.clearSecondaryProgress();
			this.startSecondaryProgressTimer();
		},

		changeSlide(index){
			this.$refs.slick.goTo(index, true)
		},
		changeSecondarySlide(index){
			this.$refs.slickSecondary.goTo(index, true)
		},
		next() {
			this.$refs.slick.next();
		},

		prev() {
			this.$refs.slick.prev();

		},

		calculateProgressIncrement(){
			 this.progressIncrement = (100.0 * this.progressDuration) / this.slickOptions.autoplaySpeed ;
		},

		startProgressTimer(){
			this.progressInterval = setInterval(()=>{
				//if( !this.progressPaused ){
					this.progress += this.progressIncrement;
				//}
			},this.progressDuration);
		},

		startSecondaryProgressTimer(){
			this.progressSecondaryInterval = setInterval(()=>{
				//if( !this.progressPaused ){
				this.progressSecondary += this.progressIncrement;
				//}
			},this.progressDuration);
		},

		killProgressTimer(){
			clearInterval(this.progressInterval);
		},

		killSecondaryProgressTimer(){
			clearInterval(this.progressSecondaryInterval);
		},

		clearProgress(){
			this.progress = 0;
			this.killProgressTimer();
		},
		clearSecondaryProgress(){
			this.progressSecondary = 0;
			this.killSecondaryProgressTimer();
		}
	},

	computed: {

		//...mapState(['locale','storage','isMobile']),


	},

	watch: {},
}
</script>

<style scoped lang="scss">
//@import "./resources/sass/variables";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.hero-slider{


	@include media-breakpoint-up(md) {
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		padding-top:0;
	}
	@include media-breakpoint-up(lg) {
		flex-direction: row;
		justify-content: space-between;
		align-items: flex-start;
		padding-top:0;
	}

	.featured{
		@include media-breakpoint-up(md) {
			//width: 74.4918%;
			//width: 75.0%;

			//padding-top: 50%;
			width:100%;
			height:auto;
			//padding-top: calc(46% + 85px);
			padding-top: 0;
			overflow: visible;

		}
		@include media-breakpoint-up(lg) {
			width: 75.0%;
			padding-top: 33.3333%;
			//padding-top: 35.6333%;
			position: relative;
			height: 0;
			overflow: hidden;
			margin-bottom: 0;
			margin-right: 0.5rem;

		}

		.slides{
			position:relative;
			@include media-breakpoint-up(lg) {
				position: absolute;
			}
		}

	}
	.sub-featured{
		width:100%;
		height:auto;
		padding-top: 0;
		overflow: visible;
		position:relative;
		@include media-breakpoint-up(lg) {
			//width: calc(100vw - 74.4918vw - 8px);
			width: calc(26.4% - 8px);
			background-color:#aaa;
			padding-top: 33%;
			position: relative;
			height: 0;
			overflow: hidden;
		}
		.slides{
			position:relative;

			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			padding:1rem 0;

			@include media-breakpoint-up(lg) {
				display: block;
				position: absolute;
				padding:0;
			}

			.slide{
				margin:0.5rem;
				width:calc(50% - 1rem);
				display:block;
				@include media-breakpoint-up(lg) {
					margin:0;
					width:auto;
				}
			}
		}


	}


}
.controls{

	top:0;
	margin-bottom:0.5rem;
	padding-left: 0.5rem;
	padding-right: 0.5rem;
	display: flex;

	.hero-slider-nav:first-child{
		@include media-breakpoint-up(md) {
			width: calc( 75.0% );
			margin-right: 0.5rem;
		}
	}
	.hero-slider-nav:last-child{
		@include media-breakpoint-up(md) {
			width: calc(26.4% - 8px);

		}
	}
}
</style>