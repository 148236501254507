<template>
    
    <transition name="modal">
        <div class="modal-mask  modal-gallery modal-dark modal-full-screen">
            <div class="modal-wrapper">
                <div class="modal-container" style="">

<!--                    <div class="modal-header">-->
<!--                        <h2>Test</h2>-->
<!--                    </div>-->

                    <div class="modal-body">

                        <slick id="AccommodationsSlider" ref="slick" :options="slickOptions" @beforeChange="handleBeforeChange" @afterChange="handleAfterChange"  @swipe="handleSwipeChange">
                            
                            <div class="carousel-cell" v-for="slide in data">
                                
                                <img  :src="storage + slide.id + '.jpg'" alt="slide" class="img-fluid carousel-cell-image">

                                <div class="caption" v-html="slide.caption"></div>
                            </div>
                            
                        </slick>

                      
                        <div class="index">
                            <span>{{currentIndex + 1}}</span>
                            /
                            <span>{{data.length}}</span>
                        </div>
                      
                      
                      <button class="btn browse prev" @click="previous">
                        <font-awesome-icon :icon="['fal', 'arrow-left']"  full-width></font-awesome-icon>
                      </button>
                      
                      <button class="btn browse next" @click="next">
                        <font-awesome-icon :icon="['fal', 'arrow-right']"  full-width></font-awesome-icon>
                      </button>
                      
                    </div>

                    <!--                    <div class="modal-footer">-->
                    <!--                        <button class="modal-default-button" @click="$emit('close')">-->
                    <!--                            Close-->
                    <!--                        </button>-->
                    <!--                    </div>-->

                    <button class="close-btn btn" @click="$emit('close')">
                        <font-awesome-icon :icon="['fal', 'times']"  full-width></font-awesome-icon>
                    </button>
                    
                </div>
            </div>
        </div>
    </transition>
    
</template>

<script>
    import Slick from 'vue-slick';
    
    export default {

        name: "GalleryModal",
        // components: {
        //     Flickity,
        //    
        // },
        
        components: { 'slick': Slick },

        props: [
            'data',
            'storage'
        ],

        data() {
            return {

                slickOptions: {
                    slidesToShow: 1,
                    infinite: true,
                    accessibility: true,
                    adaptiveHeight: false,
                    points: true,
                    draggable: true,
                    edgeFriction: 0.30,
                    swipe: true,
                    vertical:false,
                    verticalSwiping:false,
                    variableWidth: false,
                    speed:150,
                    autoplay: false,
                    mobileFirst:true,
                    initialSlide:0,
                    centerMode: false,
                    arrows:false,
                   // lazyLoad:'ondemand',

                    responsive: [
                        {
                            breakpoint: 767,
                            settings: {
                                swipeToSlide: false,
                                swipe: true,
                                centerMode: false,
                                arrows: false,
                            }
                        },
                        {
                            breakpoint: 1023,
                            settings: {
                                
                                swipeToSlide: false,
                                swipe: true,
                                centerMode: false,
                                arrows: false,
                            }
                        },
                    ]
                },
                currentIndex:0
            }
        },

        mounted() {

            this.$store.commit('storeShowingModal',true);
            //this.$nextTick(() => {

                //
                
                // this.$refs.flickity.on('change', (event) => {
                //     this.currentIndex = event + 1;
                // });

            //});
        },

        methods: {
            next() {
                this.$refs.slick.next();
            },

            previous() {
                this.$refs.slick.prev();
            },
            handleChangeSlide(i){
                // console.log('change slide to', i);
                this.$refs.slick.goTo(i);
                this.currentIndex = i;

            },

            handleBeforeChange(event, slick, currentSlide, nextSlide) {
                //console.log('handleBeforeChange', event, slick, currentSlide, nextSlide);

                this.currentIndex = nextSlide;

                //this.$emit('slideChange',this.currentIndex);
            },

            handleAfterChange(event, slick, currentSlide){
                // console.log('handleBeforeChange',currentSlide);
                this.currentIndex = currentSlide;
            },
            handleSwipeChange(event, slick, direction){
                // console.log('swipe slide to', direction);
            },
        }
    }
</script>

<style scoped>

    .carousel-cell-image {
        transition: opacity 0.4s;
    }

    .carousel-cell-image.lazy-loaded {
        opacity: 1;
    }
     
    .carousel {
        background: #EEE;
    }

    .carousel-cell {
        padding-bottom:20px;
    }

    .caption{
        font-size:16px;
        color:white;
        text-align:center;
    }

    .index{
        font-size:16px;
        color:rgba(255,255,255,0.6);
        position:absolute;
        top:0;
    }
    
    .index span{
        color:white;
    }

</style>