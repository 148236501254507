<template>
    <div class="vid-takeover showable" :class="{'show':show}">
<!--
        <div v-if="isMobile"
             class="embed-responsive  d-lg-none" :style="{ paddingBottom: mobilePadding + '%' }">
            <iframe ref="mobilePlayer" :src="'https://player.vimeo.com/video/' + mobile +'?background=1' + '&autopause=' + (autoPause ? '1':'0')" width="640" height="480" frameborder="0" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen="allowfullscreen"></iframe>
        </div>
        <div v-if="!isMobile"
             class="embed-responsive  d-none d-lg-block" :style="{ paddingBottom: desktopPadding + '%' }">
            <iframe ref="desktopPlayer" :src="'https://player.vimeo.com/video/' + desktop +'?background=1' + '&autopause=' + (autoPause ? '1':'0')" width="1920" height="1080" frameborder="0" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen="allowfullscreen"></iframe>
        </div>
-->

	    <div

	         class="embed-responsive"
	         :class="isMobile?'d-lg-none':'d-none d-lg-block'"
	         :style="{ paddingBottom: (isMobile?mobilePadding:desktopPadding) + '%' }">
	        <iframe ref="player" id="Player"
	                :src="calculatedSource"
	                :width="isMobile?640:1920" :height="isMobile?480:1080"
	                frameborder="0"
	                webkitallowfullscreen=""
	                mozallowfullscreen=""
	                allowfullscreen="allowfullscreen"></iframe>
	    </div>



	    <div class="showable" :class="{'show':show}">
        <slot></slot>
	    </div>
    </div>
</template>

<script>

    import { mapState, mapMutations } from 'vuex';

    import Player from '@vimeo/player';

    export default { 

        name: "VimeoResponsiveVideo",

        props: {
            desktop: {
                type: String,
                required: false
            },
            mobile: {
                type: String,
                default: false
            },
            mobileWidth: {
                type: Number,
                default: null
            },
            mobileHeight: {
                type: Number,
                default: null
            },
            desktopWidth: {
                type: Number,
                default: null
            },
            desktopHeight: {
                type: Number,
                default: null
            },
            autoPause: {
                type: Boolean,
                default: true
            },
            cssClass: {
                type: String,
                required: false
            },
        },
        
        data() {
            return {
            	show:false
            }
        },

        mounted() {

            //console.log('is mobile? ', this.isMobile);


	        let player = new Player('Player');
	        player.on('play', ()=> {
		        this.show = true;
	        });
        },

        methods: {

            //...mapMutations(['isMobile']),


        },

        computed: {

            ...mapState(['locale','storage','isMobile']),

            desktopPadding(){
                return (this.desktopHeight / this.desktopWidth) * 100;
            },
            
            mobilePadding(){
                return (this.mobileHeight / this.mobileWidth) * 100;
            },

	        calculatedSource(){
            	return 'https://player.vimeo.com/video/' + (this.isMobile?this.mobile:this.desktop) +'?background=1' + '&autopause=' + (this.autoPause ? '1':'0')
	        }

        },
    }
</script>

<style scoped>
	.showable{
		opacity: 0;
		transition:opacity 1s;
	}
	.showable.show{
		opacity: 1;
	}
	.showable .center-block{
		opacity: 1 !important;
	}

</style>