<template>

    <transition name="modal">
        <div class="modal-mask" @click="$emit('close')">
            <div class="modal-wrapper">
                <div class="modal-container" style="max-width: 300px;">
                    <button class="close-btn btn" @click="$emit('close')">
                        <font-awesome-icon :icon="['fal', 'times']"  full-width></font-awesome-icon>
                    </button>
                    <div class="modal-header">
                        <slot name="header">
                            Adventures
                        </slot>
                    </div>

                    <div class="modal-body">
                        <slot name="body">
                            no activities
                        </slot>
                    </div>


                </div>
            </div>
        </div>
    </transition>
    
</template>

<script>

    export default {

        name: "GroupAdventureModal",

        props: [],

        data() {
            return {

            }
        },

        mounted() {


        },

        methods: {
            
            
        },
    }
</script>

<style scoped>

</style>
