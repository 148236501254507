<template>

	<nav class="mobile-menu" :class="{'active':mobileMenuOn}" role="navigation">

		<div class="mb-4">

			<nav-link :title="'The Resort &amp; Ranch'"
			          :item="routes.resorts"
			          :active="activePanel === 'resort'"
			          @active="activePanel=arguments[0]?'resort':null">
				<slot name="resort"></slot>
			</nav-link>

			<nav-link :title="'Luxury Homes'"
			          :item="routes.homes"
			          :active="activePanel === 'homes'"
			          @active="activePanel=arguments[0]?'homes':null">
				<slot name="homes"></slot>
			</nav-link>

			<nav-link :title="'Luxury Camping'"
			          :item="routes.glamping"
			          :active="activePanel === 'glamping'"
			          @active="activePanel=arguments[0]?'glamping':null">

				<slot name="glamping"></slot>
			</nav-link>

			<nav-link :title="'Dining'"
			          :item="routes.dining"
			          :active="activePanel === 'dining'"
			          @active="activePanel=arguments[0]?'dining':null">

				<slot name="dining"></slot>
			</nav-link>

			<nav-link :title="'Spa'"
			          :item="routes.spa"
			          :active="activePanel === 'spa'"
			          @active="activePanel=arguments[0]?'spa':null">

				<slot name="spa"></slot>
			</nav-link>

			<nav-link :title="'Activities'"
			          :item="routes.activities"
			          :active="activePanel === 'activities'"
			          @active="activePanel=arguments[0]?'activities':null">

				<slot name="activities"></slot>
			</nav-link>

			<nav-link :title="'Events'"
			          :item="routes.events"
			          :active="activePanel === 'events'"
			          @active="activePanel=arguments[0]?'events':null">

				<slot name="events"></slot>
			</nav-link>

			<nav-link :title="'Groups'"
			          :item="routes.groups"
			          :active="activePanel === 'groups'"
			          @active="activePanel=arguments[0]?'groups':null">

				<slot name="groups"></slot>
			</nav-link>

		</div>

		<div>

			<a class="check-availability showPopUp track-link d-inline-block mb-3"
			   data-track-link="global-events" data-track-label="Book Now button - global nav"
			   :href="routes.reserve" data-class="" data-width="0">
				Check Availability
			</a>

			<a href="tel:8775806343" class="d-block tele track-link"
			   data-track-link="global-events" data-track-label="Phone Number - global nav">
				<span class="ph-trck">(877) 580-6343</span>
			</a>

		</div>

	</nav>

</template>

<script>

import { mapState, mapGetters, mapMutations } from 'vuex';
import NavLink from "./partials/NavLink";

export default {

	name: "MobileNavigation",
	components: {NavLink},
	props: {

	},

	data() {
		return {
			routes:[],
			activePanel:null,
		}
	},

	mounted() {


	},

	methods: {

		...mapMutations(['setMobileNavMenu','setMobileNavMenu','setMobileNavSubmenuOn']),

	},

	computed: {

		...mapState(['navigationRoutes','storage','isMobile','mobileMenuOn','mobileMenuSubmenuOn']),


	},

	watch: {
		navigationRoutes:function(val){
			this.routes = val;
		},
		mobileMenuOn:function(val){
			console.log('mobileMenuOn',val);
			if( !val ){
				this.activePanel = null;
				this.setMobileNavSubmenuOn(false);
			}else{

			}
		},
		mobileMenuSubmenuOn:function(val){
			if( !val ){
				this.activePanel = null;
			}
		}
	},
}
</script>

<style scoped lang="scss">
//@import "./resources/sass/variables";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

nav.mobile-menu {

	position: fixed;
	top:0; right:0;
	z-index: 10;

	background-color: #12100c;

	display: flex;

	width: 320px;
	height:100vh;

	overflow-y: scroll;
	overflow-x: hidden;

	flex-direction: column;

	padding:4rem 1.25rem 2rem;

	color:#e9dec4;

	font-family: 'Poppins', serif;
	font-size:1.125rem;

	text-align: right;

	transform:translateX(320px);
	transition:transform 0.2s ease;

	&.active{
		transform:translateX(0);
	}
}

</style>