<template>
    
    <div>

        <v-style v-if="width">
            .floorplan-modal .modal-wrapper .modal-container {
                max-width: {{this.width + "px"}};
            }
        </v-style>
        
        <div @click="active = true">
            <slot  name="button"></slot>
        </div>
        

        <transition name="modal">
<!--            <div class="modal-mask" @click="active = false" v-if="active">-->
            <div class="modal-mask floorplan-modal" v-if="active">
                <div class="modal-wrapper">
                    <div class="modal-container" :class="css" style="max-width: 600px;padding: 20px 30px;">
                        <button class="close-btn btn" @click="active = false">
                            <font-awesome-icon :icon="['fal', 'times']"  full-width></font-awesome-icon>
                        </button>
                        <div class="modal-header" style="padding:0;border-color:#ebebeb;">
                            <h2 v-html="title" style="margin-top:0"></h2>
                        </div>

                        <div class="modal-body" style="padding:15px 0;">
                            
                            <div class="tabs">
                                <button class="tab btn" v-for="(plan,index) in data" :key="index" @click="currentIndex = index" :class="{'active':currentIndex===index}">
                                    {{plan.title}}
                                </button>
                            </div>
                            
                            <div v-for="(plan,index) in data" :key="index" v-show="currentIndex===index">

                                <img :src="storage + plan.path" alt="" class="img-fluid">
                                
                                <div class="sqft" :class="plan.sfpos">
                                    {{plan.size}} sq. ft.
                                </div>
                                
                            </div>
                            
                        </div>

<!--                    <div class="modal-footer">-->
<!--                        <button class="modal-default-button" @click="$emit('close')">-->
<!--                            Close-->
<!--                        </button>-->
<!--                    </div>-->
                    </div>
                </div>
            </div>
        </transition>
        
    </div>
    
</template>

<script>

    export default {

        name: "FloorplanModal",

        props: [
            'title',
            'css',
            'width',
            'data',
            'storage'
        ],

        data() {
            return {
                active:false,
                currentIndex:0
            }
        },

        mounted() {


        },

        methods: {
            
            openModal: function(){
                
                this.active = true;
                
            },

            closeModal: function(){

                this.active = false;

            }
            
        },
    }
</script>

<style scoped>


</style>