<template>
    
    <div>
    
        <div @click.prevent="handleClick">
            <slot></slot>
        </div>
        
        
        <transition name="modal">
            <div class="modal-mask  modal-gallery" v-if="active">
                <div class="modal-wrapper" style="height: 100%;">
                    <div class="modal-container" style="">
                        <button class="btn close-btn" @click="active = !active">
                            <font-awesome-icon :icon="['fal', 'times']"  full-width></font-awesome-icon>
                            <!--<i class="fal fa-times"></i>-->
                        </button>
                        <div class="modal-body">
                            <div>
                            <div class="embed-responsive" :class="getEmbedRatio">
                                
                                <iframe :src="url" 
                                        class="embed-responsive-item"
                                        frameborder="0" 
                                        allow="autoplay; fullscreen" 
                                        allowfullscreen></iframe>

                            </div>
                            
                            <div class="video-modal-caption text-center"  v-html="caption"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>

    </div>
</template>

<script>

    //import { mapState, mapMutations } from 'vuex';

    export default {

        name: "VideoPlayerModal",

        props: {
            videoId: {
                type: String,
                required: false
            },
            videoService: {
                type: String,
                required: false
            },
            source: {
                type: String,
                required: false
            },
            autoplay: {
                type: Boolean,
                default: false
            },
            showControls: {
                type: Boolean,
                default: true
            },
            caption: {
                type: String
            },
            aspectRatio: {
                type: String,
                default: '16by9'
            },
            autoPause: {
                type: Boolean,
                default: true
            },
            callbackRestartVideo: {
                type: String,
                required: false
            },
        },

        data() {
            return {
                active:false,
                url:'',
                restartParentVideo:null
            }
        },

        mounted() {
            
            if( this.videoId && this.videoService ){
                
                this.url = this.getVideoUrl();
                
            }else{
                if (this.source.includes('youtube.com') || this.source.includes('youtu.be')) {
                    this.url = this.getYoutubeVideo(this.source);
                } else if (this.source.includes("vimeo")) {
                    this.url = this.getVimeoVideo(this.source);
                };
            }
            


            if( this.callbackRestartVideo ){
               // console.log('callback video was declared',this.callbackRestartVideo);
                
                this.restartParentVideo = document.querySelector(this.callbackRestartVideo + ' iframe');
               
               // console.log('callback video found:',this.restartParentVideo);

            }
        },

        methods: {

            //...mapMutations(['isMobile']),

            handleClick(e){
                //console.log('clicked');
                this.active = !this.active;
            },

            getYoutubeVideoId(url) {
                let regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
                let match  = url.match(regExp);

                return (match !== undefined && match[7] !== undefined) ? match[7] : false;
            },
            
            getVimeoVideoId(url) {
                return /(vimeo(pro)?\.com)\/(?:[^\d]+)?(\d+)\??(.*)?$/.exec(url)[3];
            },
            
            getPosterFrame(){
                if (this.source.includes('youtube.com') || this.source.includes('youtu.be')) {
                    let videoId = this.getYoutubeVideoId(this.source);

                    return 'https://img.youtube.com/vi/' + videoId + '/hqdefault.jpg';
                } else if (this.source.includes('vimeo.com')) {
                    var videoDetails = this.httpGet('https://vimeo.com/api/v2/video/54802209.json');

                    return videoDetails[0].thumbnail_medium;
                } else {
                    return src;
                }
            },
            
            getVideoUrl() {
                let videoUrl = null;
                
                if (this.videoService === 'youtube') {
                    videoUrl = 'https://www.youtube.com/embed/' + this.videoId + '?rel=0';

                    if (this.autoplay) {
                        videoUrl += '&amp;autoplay=1';
                    }
                    if (!this.showControls) {
                        videoUrl += '&amp;controls=0';
                    }
                }else{
                    videoUrl = 'https://player.vimeo.com/video/'+ this.videoId + '?api=1';
                    if (this.autoplay) {
                        videoUrl += '&autoplay=1';
                    }
                    if (!this.autoPause) {
                        videoUrl += '&autopause=0';
                    }
                }

                return videoUrl;
            },
            getYoutubeVideo(url) {
                let videoUrl = "";
                let videoId  = this.getYoutubeVideoId(url);

                if (videoId) {
                    videoUrl = 'https://www.youtube.com/embed/' + videoId + '?rel=0';

                    if (this.autoplay) {
                        videoUrl += '&amp;autoplay=1';
                    }
                    if (!this.showControls) {
                        videoUrl += '&amp;controls=0';
                    }
                }

                return videoUrl;
            },

            getVimeoVideo(url) {
                let videoUrl = "";
                const vimoId = /(vimeo(pro)?\.com)\/(?:[^\d]+)?(\d+)\??(.*)?$/.exec(url)[3];

                if (vimoId !== undefined) {
                    videoUrl = 'https://player.vimeo.com/video/'+ vimoId + '?api=1';
                    if (this.autoplay) {
                        videoUrl += '&autoplay=1';
                    }
                    if (!this.autoPause) {
                        videoUrl += '&autopause=0';
                    }
                }

                return videoUrl;
            },

            listenForEscape(e){
                if (event.which === 27) {
                    this.active = false;
                }
            }
        },

        computed: {

            //...mapState(['locale','storage','isMobile']),

            getEmbedRatio(){
                return 'embed-responsive-' + this.aspectRatio;
            }

        },
        
        watch: {
            active:function(newValue,oldValue){
               // console.log('active change',newValue);
                let body = document.body;
                if( newValue ){
                    
                    body.classList.add('show-modal');
                    window.addEventListener('keyup', this.listenForEscape);
                }else{

                    body.classList.remove('show-modal');

                    window.removeEventListener('keyup', this.listenForEscape);
                    
                    if(this.restartParentVideo){
                        let source = this.restartParentVideo.getAttribute('src');
                        this.restartParentVideo.setAttribute('src','');
                        this.restartParentVideo.setAttribute('src',source);
                    }
                    
                }
            }
        }
    }
</script>

<style scoped>

    .modal-container{
        max-width: 1000px;
        box-shadow:none;
        background-color:transparent;
        position:relative;
    }
    .video-modal-caption{
        color:white;
        text-align: center;
        margin-top:20px;
    }
    
    button.close-btn{
        background:transparent;
        color:rgba(255,255,255,0.85);
        font-size:46px;
        position:absolute;
        top:-84px; 
        right:0;
    }
    button.close-btn:hover{
        color:rgba(255,255,255,1);
    }

    .modal-body {
        height: 100%;
        width: 100%;
        padding:15px;
    }
    
    .modal-body > div{
        /*position: absolute;
        top: 50%;
        left: 15px;
        width: calc(100% - 30px);
        transform: translateY(-50%);*/
    }

    @media (min-width: 768px) {
        .modal-body {
            /*padding:0 30px;*/
            padding:0;
        }
        .video-modal-caption{
            display:block;
        }
    }
    @media (min-width: 1280px) {
        .modal-body {
            padding:0;
            /*padding:0 60px;*/

        }
    }
    @media (min-width: 1920px) {
        .modal-body {
            padding:0 70px;

        }
    }
    @media only screen
    and (max-device-width: 992px)
    and (orientation: landscape) {
        .video-modal-caption{
            display:none;
        }
    }


</style>