require('./bootstrap');
require('slick-carousel');
//require('./vendor/bootstrap-offcanvas/bootstrap.offcanvas');
require('jquery-popup-overlay');
const bodyScrollLock = require('body-scroll-lock');
window.disableBodyScroll = bodyScrollLock.disableBodyScroll;
window.enableBodyScroll = bodyScrollLock.enableBodyScroll;
window.clearAllBodyScrollLocks = bodyScrollLock.clearAllBodyScrollLocks;
import store from './stores/index'
import debounce from 'lodash.debounce';
import VModal from 'vue-js-modal'
import VueScrollFixedNavbar from "vue-scroll-fixed-navbar";
//import vuePositionSticky from 'vue-position-sticky'
//import VueSilentbox from 'vue-silentbox'
import PhotoSwipe from 'photoswipe'
import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default'
import SidePopUp from './SidePopUp.js';
import LeftNav from './LeftNav.js';
import RightNav from './RightNav.js';
import Home from './home.js';
import {EventPopUpLink} from './directives/EventPopUpLink';
import VueMatchHeights from 'vue-match-heights';
import VueCollapse from 'vue2-collapse'
//import { VLazyImagePlugin } from "v-lazy-image";
import Vue2TouchEvents from 'vue2-touch-events'
//import lozad from 'lozad'
import ViewportShow from './directives/ViewportShow'
import lazySizes from 'lazysizes';
import 'lazysizes/plugins/bgset/ls.bgset'
lazySizes.cfg.lazyClass = 'lazy';


import { library as FontAwesomeLibrary } from '@fortawesome/fontawesome-svg-core'

import { faCaretDown } from '@fortawesome/pro-solid-svg-icons'
import { faCaretRight } from '@fortawesome/pro-light-svg-icons'

import { faComment } from '@fortawesome/pro-solid-svg-icons'
import { faComments } from '@fortawesome/pro-solid-svg-icons'
import { faComments as faCommentsLight } from '@fortawesome/pro-light-svg-icons'


import { faArrowCircleRight } from '@fortawesome/pro-light-svg-icons'
import { faArrowDown } from '@fortawesome/pro-light-svg-icons'
import { faArrowToBottom as faArrowToBottomLight } from '@fortawesome/pro-light-svg-icons'
import { faArrowRight } from '@fortawesome/pro-light-svg-icons'

import { faExternalLink } from '@fortawesome/pro-light-svg-icons'

import { faPlayCircle } from '@fortawesome/pro-solid-svg-icons'
import { faPlayCircle as faPlayCircleRegular } from '@fortawesome/pro-regular-svg-icons'
import { faPlayCircle as faPlayCircleLight } from '@fortawesome/pro-light-svg-icons'

import { faPlusCircle as faPlusCircleLight } from '@fortawesome/pro-light-svg-icons'

import { faAngleDoubleRight } from '@fortawesome/pro-solid-svg-icons'
import { faAngleDoubleRight as faAngleDoubleRightLight } from '@fortawesome/pro-light-svg-icons'

import { faAngleUp as faAngleUpLight } from '@fortawesome/pro-light-svg-icons'
import { faAngleRight } from '@fortawesome/pro-light-svg-icons'
import { faAngleRight as faAngleRightRegular } from '@fortawesome/pro-regular-svg-icons'

import { faArrowRight as faArrowRightLight } from '@fortawesome/pro-light-svg-icons'
import { faArrowLeft as faArrowLeftLight } from '@fortawesome/pro-light-svg-icons'


import { faAngleLeft } from '@fortawesome/pro-light-svg-icons'
import { faAngleLeft as faAngleLeftLight } from '@fortawesome/pro-light-svg-icons'

import { faPlay } from '@fortawesome/pro-light-svg-icons'
import { faTimes } from '@fortawesome/pro-light-svg-icons'

import { faFile } from '@fortawesome/pro-solid-svg-icons'
import { faFilePdf } from '@fortawesome/pro-solid-svg-icons'
import { faFilePdf as faFilePdfLight } from '@fortawesome/pro-light-svg-icons'

import { faCampground as faCampgroundRegular } from '@fortawesome/pro-regular-svg-icons'
import { faSquare } from '@fortawesome/pro-solid-svg-icons'
import { faCheckSquare } from '@fortawesome/pro-solid-svg-icons'
import { faEnvelope } from '@fortawesome/pro-solid-svg-icons'
import { faCertificate } from '@fortawesome/pro-solid-svg-icons'
import { faHeart } from '@fortawesome/pro-solid-svg-icons'
import { faStar as faStarSolid } from '@fortawesome/pro-solid-svg-icons'
import { faCircle } from '@fortawesome/pro-solid-svg-icons'
import { faCheck } from '@fortawesome/pro-solid-svg-icons'
import { faCheck as faCheckLight } from '@fortawesome/pro-light-svg-icons'
import { faExpand as faExpandLight } from '@fortawesome/pro-light-svg-icons'
import { faSearchPlus } from '@fortawesome/pro-light-svg-icons'
import { faCompass as faCompassLight } from '@fortawesome/pro-light-svg-icons'
import { faCalendar } from '@fortawesome/pro-light-svg-icons'
import { faEdit } from '@fortawesome/pro-light-svg-icons'
import { faMap as faMapLight } from '@fortawesome/pro-light-svg-icons'
import { faClone } from '@fortawesome/pro-light-svg-icons'
import { faSignOut } from '@fortawesome/pro-light-svg-icons'
import { faBullhorn } from '@fortawesome/pro-solid-svg-icons'
import { faExclamation } from '@fortawesome/pro-solid-svg-icons'
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons'
import { faBell } from '@fortawesome/pro-solid-svg-icons'
import { faPhone } from '@fortawesome/pro-solid-svg-icons'
import { faEye } from '@fortawesome/pro-solid-svg-icons'
import { faBookOpen } from '@fortawesome/pro-solid-svg-icons'
import { faPaperPlane } from '@fortawesome/pro-solid-svg-icons'
import { faLocationArrow } from '@fortawesome/pro-solid-svg-icons'
import { faShoppingBag as faShoppingBagLight } from '@fortawesome/pro-light-svg-icons'
import { faGift as faGiftLight } from '@fortawesome/pro-light-svg-icons'
import { faLeaf } from '@fortawesome/pro-light-svg-icons'
import { faShoppingCart as faShoppingCartLight } from '@fortawesome/pro-light-svg-icons'
import { faYoutube } from '@fortawesome/free-brands-svg-icons'
import { faVimeo } from '@fortawesome/free-brands-svg-icons'
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons'
import { faPinterest } from '@fortawesome/free-brands-svg-icons'
import { faTwitter } from '@fortawesome/free-brands-svg-icons'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'


FontAwesomeLibrary.add(faExternalLink,faArrowRight,faCaretDown,faArrowToBottomLight,faCaretRight, faBell,faEnvelope,faFilePdf,faFacebookSquare,faPinterest,faTwitter,faPlayCircle,faPlayCircleRegular, faPlayCircleLight,faInstagram,faComment,faComments,faCommentsLight,faSearchPlus,faAngleLeftLight,faFilePdfLight,faGiftLight,faShoppingCartLight,faAngleDoubleRight,faShoppingBagLight,faAngleRight,faAngleRightRegular,faAngleLeft,faSignOut,faCompassLight,faAngleDoubleRightLight,faPlay,faTimes,faLeaf,faArrowCircleRight,faLocationArrow,faEye,faArrowDown,faCheck,faCheckLight,faStarSolid,faArrowRightLight,faArrowLeftLight,faAngleUpLight,faCampgroundRegular,faCircle,faYoutube,faVimeo,faHeart,faFile,faSquare,faBookOpen,faPaperPlane,faCertificate,faExclamation,faExclamationTriangle,faBullhorn,faEdit,faCalendar,faPlusCircleLight,faExpandLight,faMapLight,faClone,faCheckSquare,faPhone);

// itinerary
import { faPlane } from '@fortawesome/pro-solid-svg-icons'
import { faGlassMartiniAlt } from '@fortawesome/pro-solid-svg-icons'
import { faShuttleVan } from '@fortawesome/pro-solid-svg-icons'
import { faUtensils } from '@fortawesome/pro-solid-svg-icons'
import { faWineGlass } from '@fortawesome/pro-solid-svg-icons'
import { faGuitar } from '@fortawesome/pro-solid-svg-icons'
import { faGuitars } from '@fortawesome/pro-solid-svg-icons'
import { faSpa } from '@fortawesome/pro-solid-svg-icons'
import { faShoppingBag } from '@fortawesome/pro-solid-svg-icons'
import { faHatChef } from '@fortawesome/pro-solid-svg-icons'
import { faSparkles } from '@fortawesome/pro-solid-svg-icons'
import { faHorse } from '@fortawesome/pro-solid-svg-icons'
import { faTrophy } from '@fortawesome/pro-solid-svg-icons'
import { faAsterisk } from '@fortawesome/pro-solid-svg-icons'
import { faDiamond } from '@fortawesome/pro-solid-svg-icons'
import { faCampfire } from '@fortawesome/pro-solid-svg-icons'
import { faCampground } from '@fortawesome/pro-solid-svg-icons'
import { faFire } from '@fortawesome/pro-solid-svg-icons'
import { faCompass } from '@fortawesome/pro-solid-svg-icons'
import { faHiking } from '@fortawesome/pro-solid-svg-icons'
import { faMap } from '@fortawesome/pro-solid-svg-icons'
import { faMapSigns } from '@fortawesome/pro-solid-svg-icons'
import { faMountains } from '@fortawesome/pro-solid-svg-icons'
import { faTreeLarge } from '@fortawesome/pro-solid-svg-icons'
import { faBackpack } from '@fortawesome/pro-solid-svg-icons'
import { faCurling } from '@fortawesome/pro-solid-svg-icons'
import { faPaintBrush } from '@fortawesome/pro-solid-svg-icons'
import { faAxe } from '@fortawesome/pro-solid-svg-icons'
import { faPopcorn } from '@fortawesome/pro-solid-svg-icons'
import { faPizzaSlice } from '@fortawesome/pro-solid-svg-icons'
import { faRing } from '@fortawesome/pro-solid-svg-icons'
import { faCamera } from '@fortawesome/pro-solid-svg-icons'
import { faEgg } from '@fortawesome/pro-solid-svg-icons'
import { faGameBoard } from '@fortawesome/pro-solid-svg-icons'
import { faFish } from '@fortawesome/pro-solid-svg-icons'
import { faBook } from '@fortawesome/pro-solid-svg-icons'
import { faBroom } from '@fortawesome/pro-solid-svg-icons'

FontAwesomeLibrary.add(faPlane,faGlassMartiniAlt,faShuttleVan,faUtensils,faWineGlass,faGuitar,faGuitars,faSpa,faShoppingBag,faHatChef,faSparkles,faHorse,faTrophy,faAsterisk,faDiamond,faCampfire,faCampground,faFire,faCompass,faHiking,faMap,faMapSigns,faMountains,faTreeLarge,faBackpack,faCurling,faPaintBrush,faAxe,faPopcorn,faPizzaSlice,faRing, faCamera,faGameBoard,faEgg,faFish,faBook,faBroom)

Vue.component('font-awesome-icon', FontAwesomeIcon);

//import HeroSliders from "./components/home/HeroSliders";
//import StatCounter from "./components/home/StatCounter";
//import AdventureSlider from "./components/home/AdventureSlider";

//Vue.component('adventure-slider', AdventureSlider);
/*
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
*/

/*
Bugsnag.start({
    appVersion: process.env.MIX_APP_VERSION,
    apiKey: process.env.MIX_BUGSNAG_API_VUE_KEY,
    plugins: [new BugsnagPluginVue()],
    
})
*/

import HoneybadgerVue from '@honeybadger-io/vue'
const honeyBadgerconfig = {
    apiKey: process.env.MIX_HONEYBADGER_VUE_API_KEY,
    environment: window.env,
    revision: process.env.MIX_HONEYBADGER_REVISION
}

//console.log('honeyBadgerconfig',honeyBadgerconfig);

Vue.use(HoneybadgerVue, honeyBadgerconfig)

Vue.config.productionTip = false;


//Vue.use(vuePositionSticky);
Vue.use(VueScrollFixedNavbar);
Vue.use(VueMatchHeights, { disabled: [768] });
Vue.use(VModal)

//Vue.use(VueSilentbox);
Vue.use(VueCollapse);
//Vue.use(VLazyImagePlugin);
Vue.use(Vue2TouchEvents);

window.HomePage = Home;
window.LeftNav = LeftNav;
window.RightNav = RightNav;
window.SidePopUp = SidePopUp;
window.PhotoSwipe = PhotoSwipe;
window.PhotoSwipeUI_Default = PhotoSwipeUI_Default;
//window.CountUp = require('countup.js/dist/countUp.js');
//window.CountUp = require('countup.js');
window.Headroom = require('headroom.js');
window.InitPhotoSwipeFromDOM = require('./vendor/photoswipe/initialize');
//window.LazyLoadXt = require('lazyloadxt/dist/jquery.lazyloadxt.js');
//window.LazyLoadXtBg = require('lazyloadxt/dist/jquery.lazyloadxt.bg.js');
//window.LazyLoadXtPicture = require('./vendor/jquery.lazyloadxt.picture.js');
//window.LazyLoadXtEx = require('lazyloadxt/dist/jquery.lazyloadxt.extra.js');
//$.lazyLoadXT.autoInit = false;
Vue.component('mobile-navigation', require('./components/global/MobileNavigation/MobileNavigation').default);

Vue.component('hero-slider', require('./components/global/HeroSlider/HeroSlider').default);

Vue.component('hero-sliders', require('./components/home/HeroSliders').default);
Vue.component('stat-counter', require('./components/home/StatCounter').default);
Vue.component('group-adventures', require('./components/groups/GroupAdventures.vue').default);
Vue.component('wedding-itinerary-button', require('./components/WeddingItineraryButton').default);
Vue.component('wedding-itinerary-modal', require('./components/WeddingItineraryModal').default);
Vue.component('group-venues', require('./components/groups/GroupVenues').default);
Vue.component('groups-sample-catering-menu-button', require('./components/groups/GroupsSampleCateringMenuButton').default);
Vue.component('groups-sample-catering-menu-modal', require('./components/groups/GroupsSampleCateringMenuModal').default);
Vue.component('glance', require('./components/accommodations/Glance').default);
Vue.component('room-types', require('./components/accommodations/RoomTypes').default);
Vue.component('reserve-camp-accordion', require('./components/accommodations/ReserveCampAccordion').default);
Vue.component('accommodations-browser', require('./components/accommodations/AccomodationsBrowser').default);
Vue.component('simple-modal', require('./components/shared/SimpleModal').default);
Vue.component('modal', require('./components/global/Modal').default);
Vue.component('floorplan-modal', require('./components/accommodations/FloorplanModal').default);
Vue.component('activity-filter', require('./components/activities/ActivityFilter').default);
Vue.component('expando', require('./components/shared/Expando').default);
Vue.component('sticky-top', require('./components/shared/StickyTop').default);
Vue.component('side-popup', require('./components/SidePopup').default);
Vue.component('event-bin-popup', require('./components/events/EventBinPopup').default);
Vue.component('global-navigation-link', require('./components/global/GlobalNavigationLink').default);
Vue.component('global-navigation-mobile-panel', require('./components/global/GlobalNavigationMobilePanel').default);
Vue.component('video-player-modal', require('./components/global/VideoPlayerModal').default);
Vue.component('vimeo-responsive-video', require('./components/home/VimeoResponsiveVideo').default);
Vue.component('html5-responsive-video', require('./components/global/Html5ResponsiveVideo').default);
Vue.component('accommodation', require('./components/home/Accommodation').default);

Vue.component('v-style', {
    render: function(createElement) {
        return createElement('style', this.$slots.default)
    }
});

Vue.directive('vpshow', ViewportShow);

/*Vue.directive('vpshow', {
    inViewport (el) {
        var rect = el.getBoundingClientRect()
        return !(rect.bottom < 0 || rect.right < 0 ||
            rect.left > window.innerWidth ||
            rect.top > (window.innerHeight - 1))
    },

    bind(el, binding) {
        el.classList.add('before-enter')
        el.$onScroll = function() {
            if (binding.def.inViewport(el)) {
                el.classList.add('enter')
                el.classList.remove('before-enter')
                binding.def.unbind(el, binding)
            }
        }
        document.addEventListener('scroll', el.$onScroll)
    },

    inserted(el, binding) {
        el.$onScroll()
    },

    unbind(el, binding) {
        document.removeEventListener('scroll', el.$onScroll)
        delete el.$onScroll
    }
});*/


Vue.directive('EventPopUpLink', EventPopUpLink);


import { mapState, mapMutations } from 'vuex';

const app = new Vue({
    el: '#app',
    store,
    props:[
        'storage',
    ],
    data:{
        scrollY: null,

    },

    created () {

        window.addEventListener("resize", debounce(this.getDeviceStates,200));
        this.getDeviceStates();
    },

    mounted() {

/*        Bugsnag.getPlugin('vue')
            .installVueErrorHandler(Vue);*/
        
        //Bugsnag.notify(new Error('Test error'))
        
        this.$store.commit('storeSettings',window.__SETTINGS__);
        this.$store.commit('storeStorage',this.$el.attributes.storage.value);

        window.addEventListener('scroll', (e) => {
            this.scrollY = Math.round(window.scrollY);
        });

        const isSafari = this.isSafari();
        this.$store.commit('storeIsSafari', isSafari);


        if (isSafari) {
            document.documentElement.classList.add('safari');
        } else {
            document.documentElement.classList.add('not-safari');
        }

        const isIE = this.isIE();

        this.$store.commit('storeIsIE', isIE);

        if (isIE) {
            document.documentElement.classList.add('ie');
        } else {
            document.documentElement.classList.add('not-ie');
        }

        const isOldEdge = this.isOldEdge();
        this.$store.commit('storeIsOldEdge', isOldEdge);
        if (isOldEdge) {
            document.documentElement.classList.add('old-edge');
        } else {
            document.documentElement.classList.add('not-old-edge');
        }


        let hamburger = document.getElementById('Hamburger');
        
        if( hamburger ){
            hamburger
                .addEventListener('touchstart', this.closeMobileMenu, false );
            document.getElementById('Mask-for-mobile-menu')
                .addEventListener('touchstart', this.closeMobileMenu, false );
            hamburger
                .addEventListener('click', this.closeMobileMenu, false );



        }
        

        document.addEventListener('lazybeforeunveil', function(e){

            let bg = e.target.getAttribute('data-background-image');

            if(bg){
                e.target.style.backgroundImage = 'url(' + bg + ')';
            }
        });

        if (!this.getCookie('global-message-winter-dismissed')) {
            this.showModal('global-message', 3000);
        }
    },

    methods: {
        ...mapMutations(['storeCurrentActiveMobileMenu','storeMobileMenuActive','storeShowBookingModal','setDevice']),

        getDeviceStates() {
            this.isMobileDevice();
            this.isTabletDevice();

            this.setDevice(this.isMobileDevice()?'mobile':this.isTabletDevice()?'tablet':'desktop');


            /* const sticky = document.getElementsByClassName('vue-position-sticky')[0];
             if( sticky ){
                 sticky.style.width =
                     sticky.parentElement.style.width =
                         sticky.parentElement.style.minWidth =
                             (window.innerWidth) + 'px';
                 // (window.innerWidth - 17) + 'px';
             }*/
        },

        showMobileNavBreakPoint() {

            return window.matchMedia("only screen and (max-width: 1200px)").matches;
           // store.commit('isMobile', isMobile);

        },
        isMobileDevice() {

            // has touch
            let hasTouch = document.documentElement.classList.contains('touchevents');
            store.commit('storeHasTouch', hasTouch);

            let isMobile = window.matchMedia("only screen and (max-width: 767px)").matches;
            store.commit('isMobile', isMobile);
            return isMobile;
        },
        isTabletDevice() {
            let isTablet = window.matchMedia("only screen and (min-width:768px) and (max-width: 991px)").matches;
            store.commit('isTablet', isTablet);
            return isTablet;
        },
        handleStickyChange(e){
            // console.log('stick change',e);
        },

        closeMobileMenu(e){
            //console.log('closeMobileMenu');
            e.stopPropagation();


            $('#Mask-for-mobile-menu').removeClass('show');
            $('.dropdown-menu').removeClass('shown');
            //$('#NavMenuCollapse').trigger('offcanvas.close');

            this.storeMobileMenuActive(!this.mobileMenuActive);
            this.storeCurrentActiveMobileMenu(null);
            //console.log('closeMobileMenu()');

        },

        showModal(name, delay = 0){
            setTimeout(()=>{
                this.$modal.show(name);
                
            },delay);
            
        },
        
        closeModal(name){
            this.$modal.hide(name);
            this.modalWasClosed(name);
        },

        modalWasClosed(name){
            this.setCookie(name + '-dismissed', 'yes', 1);

        },

        setCookie(cname, cvalue, exdays) {
            let d = new Date();
            d.setTime(d.getTime() + (exdays*24*60*60*1000));
            let expires = "expires="+ d.toUTCString();
            document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
        },

        getCookie(cname) {
            let name = cname + "=";
            let decodedCookie = decodeURIComponent(document.cookie);
            let ca = decodedCookie.split(';');
            for(let i = 0; i <ca.length; i++) {
                let c = ca[i];
                while (c.charAt(0) == ' ') {
                    c = c.substring(1);
                }
                if (c.indexOf(name) == 0) {
                    return c.substring(name.length, c.length);
                }
            }
            return "";
        },

        isIE() {

            let ua = window.navigator.userAgent;

            //console.log('is this IE???',ua);

            // Test values; Uncomment to check result …

            // IE 10
            // ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';

            // IE 11
            // ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';

            // Edge 12 (Spartan)
            // ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';

            // Edge 13
            // ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';

            var msie = ua.indexOf('MSIE ');
            if (msie > 0) {
                // IE 10 or older => return version number
                let check = parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
                if (check) {
                    //this.lazyLoadInstance.update();
                }
                return check;
            }

            var trident = ua.indexOf('Trident/');
            if (trident > 0) {
                // IE 11 => return version number
                var rv = ua.indexOf('rv:');
                let check = parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
                if (check) {
                    //this.lazyLoadInstance.update();
                }
                return check;
            }
            /*
                        var edge = ua.indexOf('Edge/');
                        if (edge > 0) {
                            // Edge (IE 12+) => return version number
                            return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
                        }*/

            // other browser
            return false;
        },

        isOldEdge(){
            let ua = window.navigator.userAgent;
            let edge = ua.indexOf('Edge/');
            if (edge > 0) {
                // Edge (IE 12+) => return version number
                return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10) <= 18;
            }

        },

        is4k() {
            return window.matchMedia("only screen and (max-width: 2560px)").matches;
        },

        isSafari() {
            let ua = window.navigator.userAgent;
            let iOS = !!ua.match(/iP(ad|od|hone)/i);
            let hasSafariInUa = !!ua.match(/Safari/i);
            let noOtherBrowsersInUa = !ua.match(/Chrome|CriOS|OPiOS|mercury|FxiOS|Firefox/i)
            let result = false;
            if (iOS) { //detecting Safari in IOS mobile browsers
                let webkit = !!ua.match(/WebKit/i);
                result = webkit && hasSafariInUa && noOtherBrowsersInUa
            } else if (window.safari !== undefined) { //detecting Safari in Desktop Browsers
                result = true;
            } else { // detecting Safari in other platforms
                result = hasSafariInUa && noOtherBrowsersInUa
            }
            return result;
        },

        handleMobileMenuState(val){
            if( val ){

                if( this.mobileMenuSubmenuOn ){
                    this.$store.commit('setMobileNavSubmenuOn',false);
                }else{
                    this.$store.commit('setMobileNavMenu',val);
                }


            }

        }
    },
    
    computed: {
        ...mapState(['isScrolledDown','isMobile','mobileMenuActive','mobileMenuOn','showingDropdownMenu','mobileMenuSubmenuOn']),

    },

    watch:{
        /*isScrolledDown(newValue){
            console.log('isScrolledDown',newValue);
        }*/

        /*showingDropdownMenu(newValue){
            console.log('showingDropdownMenu',newValue);
        },*/

        mobileMenuActive(newValue){
            // 2019/11/24: This will add a class the prevents all mouse/touch events while the mobile menu is opened. This solves isues with other clickable/touchable events that are under the mobile menu that are clicked to close the mobile menu. 
            let m = document.querySelector('#app .main');
            if(newValue){
                m.classList.add('freeze');
            }else{
                setTimeout(function(){
                    m.classList.remove('freeze');  
                },500)
            }
        },

        mobileMenuOn: function (val) {

            let body = document.getElementsByTagName("BODY")[0];

            if( val ){
                body.classList.add('offcanvas-stop-scrolling');
            }else{
                body.classList.remove('offcanvas-stop-scrolling');
                this.storeCurrentActiveMobileMenu(null)
            }

        }
    }

});


$(function() {
    $('[data-toggle="tooltip"]').tooltip({
        container:'body'
    });

    $('a.scroll').on('click', function(e) {
        e.preventDefault();
        // target element id
        let id = $(this).attr('href');

       // console.log(id);

        if( id === '#' ){
            $('body, html').animate({scrollTop: 0});
            return;
        }
        // target element
        let $id = $(id);


        if ($id.length === 0) {
            return;
        }



        // top position relative to the document
        let pos = $id.offset().top - 40;

        // animated top scrolling
        $('body, html').animate({scrollTop: pos});
    });
    let scroll = getParameterByName('scroll');

    if( scroll !== null ){
        setTimeout(function(){

            let ele = $('#'+scroll);
            if( ele.length ){
                let pos = ele.offset().top - 40;

                // animated top scrolling
                $('body, html').animate({scrollTop: pos});
            }


        },1500);

    }
    
/*    $('#NavMenuCollapse').on('hide.bs.offcanvas',function(){
        //console.log("CLOSE MENU 0");
        $('header .navbar-toggle').addClass('is-open');
        //$('#Mask-for-mobile-menu').removeClass('show');
        //$('.dropdown-menu').removeClass('shown');

    });

    $('#NavMenuCollapse').on('show.bs.offcanvas',function(){
        //console.log("OPEN MENU");
        $('header .navbar-toggle').removeClass('is-open');
        $('#Mask-for-mobile-menu').addClass('show');

    });*/

    /*$('#Mask-for-mobile-menu').on('click',function(){
        //console.log("CLOSE MENU 1");
        $('#NavMenuCollapse').trigger('offcanvas.close');
        //$('#Mask-for-mobile-menu').removeClass('show');
        $('.dropdown-menu').removeClass('shown');
    });*/

    //$('#Mask-for-mobile-menu').on('touchstart',function(){
        //console.log("CLOSE MENU 2")
        //$('#NavMenuCollapse').trigger('offcanvas.close');
       // $('#Mask-for-mobile-menu').removeClass('show');
        //$('.dropdown-menu').removeClass('shown');
    //});

    // $('#Nav .dropdown').on('mouseenter', function(e){
    //    
    //     console.log('hover');
    //
    //     $('#Nav .dropdown').removeClass('hover');
    //    
    //     setTimeout(function(){
    //        
    //         $(e.currentTarget).addClass('hover');
    //        
    //     },250);
    //    
    //    
    // });
    // $('#Nav .dropdown').on('mouseleave', function(e){
    //
    //     $('#Nav .dropdown').removeClass('hover');
    //     $(e.currentTarget).removeClass('hover');
    //
    //
    //
    // });
    $( ".email-signup" ).on( "submit", function( e ) {

        e.preventDefault();

        let url = $(this).attr('action');
        let btn = $(this).find('.btn');
        console.log(btn);
        $.ajax({
            type: "POST",
            url: url,
            data: $(this).serialize(),
            success: function(data) {
                //btn.hide();
                $('#SendError').removeClass('show');
                
                if(!data.success){
                    $('#ErrorMsg').html(data.message);
                    $('#SendError').addClass('show');
                }else{
                    
                    $('#EmailSignUpWrapper').addClass('success');
                }
            },
            error: function(jqXHR, textStatus, errorThrown) {
                if( jqXHR.status == 422 ){
                    var responseText = jQuery.parseJSON(jqXHR.responseText);
                    $.each(responseText, function(k, v) {
                        $('#ErrorMsg').append('<li>' + v + '</li>');
                    });
                    $('#SendError').addClass('show');
                }
            }
        });

    });
    
    
    let navAniTimeout = null;
    
    $('#Nav').on('mouseover', function(e){
       
        clearTimeout(navAniTimeout);
        navAniTimeout = window.setTimeout(setNavAni,300);
        
    });
    $('#Nav').on('mouseout', function(e){

        //$('#Nav').removeClass('no-animation');

        navAniTimeout = window.setTimeout(clearNavAni,300);

    });
    
    
    
});

function setNavAni(){
    $('#Nav').addClass('no-animation');
}
function clearNavAni(){
    $('#Nav').removeClass('no-animation');
}

//
// var _overlay = document.getElementById('Mask-for-mobile-menu');
// var _clientY = null; // remember Y position on touch start
//
// _overlay.addEventListener('touchstart', function (event) {
//     if (event.targetTouches.length === 1) {
//         // detect single touch
//         _clientY = event.targetTouches[0].clientY;
//     }
// }, false);
//
// _overlay.addEventListener('touchmove', function (event) {
//     if (event.targetTouches.length === 1) {
//         // detect single touch
//         disableRubberBand(event);
//     }
// }, false);
//
// function disableRubberBand(event) {
//     var clientY = event.targetTouches[0].clientY - _clientY;
//
//     if (_overlay.scrollTop === 0 && clientY > 0) {
//         // element is at the top of its scroll
//         event.preventDefault();
//     }
//
//     if (isOverlayTotallyScrolled() && clientY < 0) {
//         //element is at the top of its scroll
//         event.preventDefault();
//     }
// }
//
// function isOverlayTotallyScrolled() {
//     // https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollHeight#Problems_and_solutions
//     return _overlay.scrollHeight - _overlay.scrollTop <= _overlay.clientHeight;
// }

function getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}