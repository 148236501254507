<template>
    
    <li class="slide"  :class="slide.css_class">

        <a :href="slide['link-to']" target="_self">

	        <picture>
		        <source :srcset="this.slide.mobile_image"  media="(max-width: 767px)" type="image/jpg" />
		        <img class="img-fluid" :src="this.slide.desktop_image" alt="">
	        </picture>

            <div class="meta">


	            <div class="left">
		            <img :src="slide.logo_image"
		                 class="slideLogo" v-if="slide.logo_image"/>
		            <div class="titles">
			            <div class="title" v-html="slide.title"></div>
		                <div class="sub-title" v-html="slide.sub_title"></div>
		            </div>
	            </div>

	            <button class="btn link-button" v-show="slide['link-to']">
		            <svg viewBox="0 0 46.67 40">
			            <path d="M.83,40A.83.83,0,0,1,0,39.17V.83A.83.83,0,0,1,.83,0a.84.84,0,0,1,.84.83V39.17A.84.84,0,0,1,.83,40Zm29.76-3.58a.83.83,0,0,1-1.17-1.18l14.4-14.41h-33a.83.83,0,1,1,0-1.66h33L29.41,4.76a.85.85,0,0,1,0-1.18A.83.83,0,0,1,30,3.33a.79.79,0,0,1,.59.25L46.43,19.42a.83.83,0,0,1,0,1.17Z" style="fill: currentColor"/>
		            </svg>

	            </button>

            </div>

        </a>

    </li>
    
</template>

<script>

    import { mapState, mapMutations } from 'vuex';

    export default {

        name: "HeroSlide",

        props: [
            'slide'
        ],

        data() {
            return {}
        },

        mounted() {

        	// TODO: will need to componentize this better to handle slides with no links, right now hard-coded <a>

        },

        methods: {

            //...mapMutations(['isMobile']),


        },

        computed: {

            ...mapState(['storage','isMobile']),

       
        },
        
        
    }
</script>
<style scoped lang="scss">

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.slide{

	> a{

		display: flex;
		flex-direction: column;

		@include media-breakpoint-up(lg) {
			flex-direction: row;
		}
	}

	picture {

		@include media-breakpoint-up(lg) {
			//width:67%;
			//width:66.02%;
			width:67%;

		}

	}
	.meta{
		position: relative;
		//width: 33%;
		background:none;
		background-color:#f7f7f7;
		color:#62605d;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		padding: 1rem;
		text-align: left;

		@include media-breakpoint-up(md) {
			//width: calc(100vw - 74.4918vw - 8px);
			//width:calc(100% - 66.02%);

		}
		@include media-breakpoint-up(lg) {
			flex-direction: column;
			justify-content: flex-end;
			align-items: flex-start;
			width:33%;
			padding: 0 1.5vw 1.25vw;
		}
		@include media-breakpoint-up(xl) {
			//padding: 0 1.875vw 3vw;
		}

		.left{
			display: flex;
			flex-direction: row;
			@include media-breakpoint-up(lg) {
				flex-direction: column;
			}
		}

		.titles{

		}

		.title{
			font-size:1.125rem;
			letter-spacing:-0.075rem;
			margin-right:0.5rem;
			@include media-breakpoint-up(lg) {
				font-size:2vw;
				margin-right:0;
			}
			@include media-breakpoint-up(xl) {
				//font-size:1.75rem;
			}
			@media (min-width: 1440px) {
				//font-size:2.25rem;
			}
		}

		.sub-title{
			font-size:0.75rem;
			@include media-breakpoint-up(lg) {
				font-size:1vw;
			}
			/*@media (min-width: 1440px) {
				font-size:0.875rem;
			}*/
		}

		.slideLogo{
			max-width: 100%;
			width:50px;
			height:auto;
			flex:0 1 auto;
			align-self: center;
			margin-right:0.5rem;
			@include media-breakpoint-up(lg) {
				align-self: flex-start;
				width:8vw;
				margin-bottom:1.5vw;
				margin-right:0;
			}
			/*@media (min-width: 1440px) {
				width:120px;
				margin-bottom:1.5rem;
			}
			@media (min-width: 1600px) {
				width:150px;
				margin-bottom:2rem;
			}*/
		}
	}

	.link-button{
		color:#fff;
		background-color:#4d87ff;
		width:60px;
		height:60px;
		border-radius:100%;
		margin-top:1.25rem;
		padding:0.5rem;
		//border: 4px solid white;
		border: none;
		flex: 0 0 auto;
		display: flex;
		justify-content: center;
		align-items: center;

		position: absolute;
		top: -90px;
		right: 13px;

		@include media-breakpoint-up(lg) {
			position: static;
			width:4.75vw;
			height:4.75vw;
			margin-top:2vw;
		}


		/*@include media-breakpoint-up(xl) {
			margin-top:1.5rem;
		}
		@media (min-width: 1440px) {
			width:78px;
			height:78px;
			margin-top:2rem;
		}*/

		&:hover{
			background-color:#2F6AE3;
		}

		svg{
			width:24px;
			@include media-breakpoint-up(lg) {
				width:2.3vw;
			}
			/*@media (min-width: 1440px) {
				width:36px;
			}*/
		}
	}
}

</style>