<template>
    
    <div>

        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12">
                    <div class="filters">
                   
                        <span>Filter</span>
                        <a href="#" @click.prevent="filterOption = 'all'"
                           :class="[filterOption==='all' ? 'active' : '']" >All Venues</a>
                        <a href="#" @click.prevent="filterOption = 'meeting-venues'"
                           :class="[filterOption==='meeting-venues' ? 'active' : '']">Meeting Venues</a>
                        <a href="#" @click.prevent="filterOption = 'indoor-dining-venues'"
                           :class="[filterOption==='indoor-dining-venues' ? 'active' : '']">Indoor Dining Venues</a>
                        <a href="#" @click.prevent="filterOption = 'outdoor-dining-venues'"
                           :class="[filterOption==='outdoor-dining-venues' ? 'active' : '']">Outdoor Dining Venues</a>
                        <a href="#" @click.prevent="filterOption = 'wedding-venues'"
                           :class="[filterOption==='wedding-venues' ? 'active' : '']">Wedding Venues</a>
                    </div>
                    
                </div>
            </div>
        </div>

        
        <section class="grid-area gray container-fluid">

            <div class="row">
                <div class="col-lg-12">
                    
                    <div class="center-up">

                            <a href="#" 
                               class="grid-item" 
                               v-for="element in filteredItems" 
                               :key="element.id"
                               @click.prevent="showCapacityModal(element)">
                
                                <div class="img-wrap">
                                    <img :src="storage + 'groups/venues/' + element.id + '-thumb.jpg'" alt="Venue" class="img-fluid ">
                                </div>
                                
                                <div class="meta">
                                    <h5>{{element.location}}</h5>
                                    <p>{{element.name}}</p>
                                    
                                    <div class="cta">
                                        <font-awesome-icon :icon="['fal', 'clone']"  full-width></font-awesome-icon>
                                        <span>Show Capacities</span>
                                    </div>
                                    
                                </div>
    
                            </a>
                        

                    </div>
                </div>
            </div>
        </section>

        <group-venue-capacity-modal 
                :data="currentCapictyData"
                v-if="showModal" @close="showModal = false"
        >
            
        </group-venue-capacity-modal>
        
        
    </div>
    
</template>

<script>
    import GroupVenueCapacityModal from './GroupVenueCapacityModal'

    export default {

        name: "GroupVenues",

        props: [
            'storage'
        ],
        directives: {
            //imagesLoaded,
        },
        components: {
            GroupVenueCapacityModal
        },

        data() {
            return {
                showModal:false,
                currentCapictyData:null,
                filterOption: 'all',
                list:[
                    {
                        id: 'hereford-pen',
                        name: 'Hereford Pen',
                        location: 'The Bull Barn',
                        image: '#',
                        categories:[
                            'meeting-venues'
                        ],
                        description: 'Hereford Pen provides an ideal setting and state-of-the-art audio-visual technology for successful presentations of any kind. The Bull Barn is an authentic Montana cattle barn that has been newly renovated into a world-class conference center and special event hall.',
                        meetingCapacity: 60,
                        receptionCapacity: 80,
                        diningCapacity:75,
                        size:'1,665 sq. ft.',
                        open:'Year–Round'
                    },
                    {
                        id: 'shorthorn-pen',
                        name: 'Shorthorn Pen',
                        location: 'The Bull Barn',
                        image: '#',
                        categories:[
                            'meeting-venues'
                        ],
                        description:'Shorthorn Pen provides an intimate setting for smaller board meetings, staff office or even a green-room for catalog shoots. The Bull Barn is an authentic Montana cattle barn that has been newly renovated into a world-class conference center and special event hall.',
                        meetingCapacity: 15,
                        receptionCapacity: 25,
                        diningCapacity:null,
                        size:'625 sq. ft.',
                        open:'Year–Round'
                    },
                    {
                        id: 'great-angus-hall',
                        name: 'Great Angus Hall',
                        location: 'The Bull Barn',
                        image: '#',
                        categories:[
                            'meeting-venues',
                            'indoor-dining-venues',
                            'wedding-venues'
                        ],
                        description:'The Great Angus Hall is an expansive banquet venue that features 3,145 square feet of space complete with a soaring vaulted ceiling and audio-visual equipment. This prodigious room serves as the perfect space for large events of all types. The hall is ideal for weddings, receptions and social gatherings of any kind for up to 150 people. ',
                        meetingCapacity: 85,
                        receptionCapacity: 200,
                        diningCapacity:175,
                        size:'3,145 sq. ft.',
                        open:'Year–Round'
                    },
                    {
                        id: 'bull-pen-patio',
                        name: 'Bull Pen Patio',
                        location: 'The Bull Barn',
                        image: '#',
                        categories:[
                            'outdoor-dining-venues',
                        ],
                        description:null,
                        meetingCapacity: null,
                        receptionCapacity: 75,
                        diningCapacity:175,
                        size:'1,680 sq. ft.',
                        open:'April-October'
                    },
                    {
                        id: 'hospitality-suite',
                        name: 'Hospitality Suite',
                        location: 'The Bunkhouse',
                        image: '#',
                        categories:[
                            'meeting-venues',
                        ],
                        description:'Formerly a hayloft and housing structure for ranch hands, this traditional barn has been converted into an executive conference room. An expansive deck allows your team members to take a break while taking in the fresh Montana air. The Bunkhouse also features a spectacular lounge area complete with a spacious living room, kitchen and dining area.',
                        meetingCapacity: 25,
                        receptionCapacity: 75,
                        diningCapacity:25,
                        size:'2,450 sq. ft.',
                        open:'Year–Round'
                    },
                    {
                        id: 'montana-long-table',
                        name: 'Montana Long Table',
                        location: 'The Cook Shack Yard',
                        image: '#',
                        categories:[
                            'meeting-venues',
                        ],
                        description:null,
                        meetingCapacity: null,
                        receptionCapacity: 80,
                        diningCapacity:150,
                        size:null,
                        open:'May–September'
                    },
                    {
                        id: 'pomp',
                        name: 'Pomp',
                        location: 'Restaurant',
                        image: '#',
                        categories:[
                            'meeting-venues',
                            'indoor-dining-venues',
                        ],
                        description:null,
                        meetingCapacity: 65,
                        receptionCapacity: 150,
                        diningCapacity:120,
                        size:'2,100 sq. ft.',
                        extSize:'1,575 sq. ft.',
                        open:'Year–Round'
                    },
                    {
                        id: 'trough',
                        name: 'Trough',
                        location: 'Restaurant',
                        image: '#',
                        categories:[
                            'meeting-venues',
                            'indoor-dining-venues',
                        ],
                        description:null,
                        meetingCapacity: 50,
                        receptionCapacity: 115,
                        diningCapacity:65,
                        size:'1,512 sq. ft.',
                        extSize:'2,560 sq. ft.',
                        open:'Year–Round'
                    },
                    {
                        id: 'tank',
                        name: 'Tank',
                        location: 'Bar & Lounge',
                        image: '#',
                        categories:[
                            'meeting-venues',
                            'indoor-dining-venues',
                        ],
                        description:null,
                        meetingCapacity: 30,
                        receptionCapacity: 50,
                        diningCapacity:50,
                        size:'1,131 sq. ft.',
                        open:'Year–Round'
                    },
                    {
                        id: 'reception-lobby',
                        name: 'Reception Lobby',
                        location: 'The Lewis & Clark Reception Barn',
                        image: '#',
                        categories:[
                            'meeting-venues',
                        ],
                        description:'At the Lewis & Clark Reception Barn, groups can relax comfortably in the leather furnishings in the lobby, check email at the business center or even take part in a game of pool. Centrally located within the Village at Paws Up, this facility is the perfect initiation point for your successful program. ',
                        meetingCapacity: null,
                        receptionCapacity: 150,
                        diningCapacity:100,
                        size:'2,040 sq. ft.',
                        open:'Year–Round'
                    },
                    /*{
                        id: 'auditorium',
                        name: 'Auditorium',
                        location: 'The Lewis & Clark Reception Barn',
                        image: '#',
                        categories:[
                            'meeting-venues',
                        ],
                        description:null,
                        meetingCapacity: 150,
                        receptionCapacity: null,
                        diningCapacity:null,
                        size:'2,560 sq. ft.',
                        open:'Year–Round'
                    },*/
                    {
                        id: 'sky-box',
                        name: 'Sky Box',
                        location: 'The Saddle Club',
                        image: '#',
                        categories:[
                            'meeting-venues',
                            'indoor-dining-venues',
                        ],
                        description:'Overlooking the indoor arena is the VIP Sky Box—the ultimate lounge for luxurious receptions. It features a fully stocked, built-in leather bar, a big screen television, plush leather seating and refined rustic tables and chairs with front-row views of all the equestrian action taking place in the arena. The intimate, unique atmosphere of the Sky Box is perfect for any gathering of up to 30 people. ',
                        meetingCapacity: 30,
                        receptionCapacity: 60,
                        diningCapacity:null,
                        size:'1,058 sq. ft.',
                        open:'Year–Round'
                    },
                    {
                        id: 'tack-room',
                        name: 'Tack Room',
                        location: 'The Saddle Club',
                        image: '#',
                        categories:[
                            'meeting-venues',
                            'indoor-dining-venues',
                        ],
                        description:'The Tack Room boasts a sophisticated, authentic barn feel providing a fun and unique party atmosphere. Iron and elk-antler candelabras cast a warm glow over walls lined with Paws Up saddles and tack. This venue is perfect for an intimate Western-style dinner or special awards ceremony. For an event that your group will be talking and blogging about for years to come, the Tack Room is the place.',
                        meetingCapacity: 20,
                        receptionCapacity: 30,
                        diningCapacity:20,
                        size:'648 sq. ft.',
                        open:'Year–Round'
                    },
                    {
                        id: 'grand-hall-foyer',
                        name: 'Grand Hall & Foyer',
                        location: 'The Saddle Club',
                        image: '#',
                        categories:[
                            'meeting-venues',
                            'indoor-dining-venues',
                        ],
                        description:null,
                        meetingCapacity: 80,
                        receptionCapacity: 100,
                        diningCapacity:100,
                        size:'5,600 sq. ft.',
                        open:'Year–Round'
                    },
                    {
                        id: 'event-arena',
                        name: 'Event Arena',
                        location: 'The Saddle Club',
                        image: '#',
                        categories:[
                            'meeting-venues',
                        ],
                        description:'The Resort at Paws Up is home to one of Montana’s premier equestrian centers—The Saddle Club at Paws Up.  at Paws Up. This authentic ranch edifice was built in 1999 to breed, house and train Paws Up’s resident horses. It’s the largest private equestrian center in Montana, featuring a 35,000-square-foot arena ideal for rodeos, equestrian shows, equestrian training, expositions or parties of any kind. Unique, luxurious, and absolutely gigantic, The Saddle Club is the perfect facility for any group or corporate function in any season. ',
                        meetingCapacity: null,
                        receptionCapacity: 400,
                        diningCapacity:400,
                        size:'34,300 sq. ft.',
                        open:'Year–Round'
                        
                    },
                    {
                        id: 'pond',
                        name: 'Pond',
                        location: 'The Saddle Club',
                        image: '#',
                        categories:[
                            'meeting-venues',
                            'outdoor-dining-venues',
                            'wedding-venues'
                        ],
                        description:null,
                        meetingCapacity: null,
                        receptionCapacity: 100,
                        diningCapacity:100,
                        size:null,
                        open:'April–October'
                    },
                    {
                        id: 'at-salmon-lake',
                        name: 'At Salmon Lake',
                        location: 'The Island Lodge',
                        image: '#',
                        categories:[
                            'meeting-venues',
                            'outdoor-dining-venues',
                        ],
                        description:'For a group experience unlike any other, how about your own private island? Just 15 minutes from The Resort, Paws Up Island Lodge at Salmon Lake promises privacy and mouthwatering cuisine rivaled only by the jaw-dropping mountain and wilderness views.',
                        meetingCapacity: null,
                        receptionCapacity: null,
                        diningCapacity:null,
                        size:null,
                        open:'Year–Round'
                    },
                    {
                        id: 'great-room',
                        name: 'Great Room',
                        location: 'The Island Lodge',
                        image: '#',
                        categories:[
                            'meeting-venues',
                        ],
                        description:null,
                        meetingCapacity: null,
                        receptionCapacity: null,
                        diningCapacity:null,
                        size:null,
                        open:'Year–Round'
                    },
                    {
                        id: 'lakeside-veranda',
                        name: 'Lakeside Veranda',
                        location: 'The Island Lodge',
                        image: '#',
                        categories:[
                            'meeting-venues',
                            'outdoor-dining-venues',
                        ],
                        description:null,
                        meetingCapacity: null,
                        receptionCapacity: 100,
                        diningCapacity:60,
                        size:null,
                        open:'May-September'
                    },
                    {
                        id: 'go-kart-track',
                        name: 'Go-Kart Track',
                        location: 'The Greenough 500',
                        image: '#',
                        categories:[
                            'meeting-venues',
                            'outdoor-dining-venues',
                        ],
                        description:null,
                        meetingCapacity: null,
                        receptionCapacity: 80,
                        diningCapacity:50,
                        size:null,
                        open:'May–September'
                    },
                    {
                        id: 'the-chuck-wagon',
                        name: '',
                        location: 'The Chuck Wagon',
                        image: '#',
                        categories:[
                            'meeting-venues',
                            'outdoor-dining-venues',
                        ],
                        description:null,
                        meetingCapacity: null,
                        receptionCapacity: 200,
                        diningCapacity:150,
                        size:null,
                        open:'May–September'
                    },
                    {
                        id: 'the-river-bend',
                        name: '',
                        location: 'The River Bend',
                        image: '#',
                        categories:[
                            'meeting-venues',
                            'outdoor-dining-venues',
                        ],
                        description:null,
                        meetingCapacity: null,
                        receptionCapacity: 200,
                        diningCapacity:150,
                        size:null,
                        open:'May-September'
                    },
                    {
                        id: 'wedding-ceremony-site',
                        name: 'Wedding Ceremony Site',
                        location: 'Miners Car Knoll',
                        image: '#',
                        categories:[
                            'wedding-venues'
                        ],
                        description:null,
                        meetingCapacity: null,
                        receptionCapacity: 150,
                        diningCapacity:null,
                        size:null,
                        open:'Year–Round'
                    },
                    {
                        id: 'wedding-ceremony-site-lr',
                        name: 'Wedding Ceremony Site',
                        location: 'Lookout Rock',
                        image: '#',
                        categories:[
                            'wedding-venues'
                        ],
                        description:null,
                        meetingCapacity: null,
                        receptionCapacity: null,
                        diningCapacity:null,
                        size:null,
                        open:'April–October'
                    },
                    {
                        id: 'clubhouse',
                        name: 'Clubhouse',
                        location: 'The Shooting Club',
                        image: '#',
                        categories:[
                            'meeting-venues',
                            'outdoor-dining-venues',
                        ],
                        description:null,
                        meetingCapacity: null,
                        receptionCapacity: 50,
                        diningCapacity:20,
                        size:null,
                        open:'Year–Round'
                    },
                    {
                        id: 'camps',
                        name: 'Cliffside Camp, Creekside Camp, Pinnacle Camp, North Bank Camp, Moonlight Camp',
                        location: 'Camp Pavilion',
                        image: '#',
                        categories:[
                            'meeting-venues',
                            'outdoor-dining-venues',
                        ],
                        description:null,
                        meetingCapacity: 30,
                        receptionCapacity: 60,
                        diningCapacity:40,
                        size:null,
                        open:'May–October'
                    },
                    // {
                    //     id: 'event-pasture',
                    //     name: 'Event Pasture',
                    //     location: 'The Morris Ranch House',
                    //     image: '#',
                    //     categories:[
                    //         'meeting-venues',
                    //         'outdoor-dining-venues',
                    //     ],
                    //     description:'Picture a lush meadow surrounded by towering pines and neighboring mountains. As your group crosses the Elk Creek Bridge, attendees are greeted with sweeping 360-degree views of the pure Montana beauty known as the Big Sky. ',
                    //     meetingCapacity: 30,
                    //     receptionCapacity: 60,
                    //     diningCapacity:40,
                    //     size:null,
                    //     open:'May-October'
                    // },
                    {
                        id: 'main-hall',
                        name: 'Main Hall',
                        location: 'Wilderness Outpost',
                        image: '#',
                        categories:[
                            'meeting-venues',
                            'indoor-dining-venues',
                        ],
                        description:null,
                        meetingCapacity: null,
                        receptionCapacity: null,
                        diningCapacity:null,
                        size:null,
                        open:'Year–Round'
                    },
                    {
                        id: 'reception-lawn',
                        name: 'Reception Lawn',
                        location: 'Wilderness Outpost',
                        image: '#',
                        categories:[
                            'meeting-venues',
                            'outdoor-dining-venues',
                        ],
                        description:null,
                        meetingCapacity: null,
                        receptionCapacity: null,
                        diningCapacity:null,
                        size:null,
                        open:'April–October'
                    },
                ]
            }
        },

        mounted() {

        },

        methods: {
            showCapacityModal(element){
                this.showModal = true;
                this.currentCapictyData = element;
            }
        },
        computed: {
            filteredItems() {
                return this.list.filter(item => {
                    
                    if( this.filterOption === 'all'){
                        return true;
                    }
                    //console.log('item',item.categories,'filter by', this.filterOption);
                    //console.log(item.categories.includes(this.filterOption));
                    
                    return item.categories.includes(this.filterOption);
                    //return item.categories.includes(this.filterOption).indexOf(this.filterOption.toLowerCase()) > -1
                })
            }
        }

    }
</script>
 
<style scoped>

</style>