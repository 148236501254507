<template>

    <div class="">
        
        <v-collapse-wrapper>

            <div class="top">
                
                <slot name="header"></slot>
  
                <button class="gallery btn" @click="showModal = true" v-if="showGallery">
                    View gallery <font-awesome-icon :icon="['fal', 'expand']"  full-width></font-awesome-icon>
                    <!--<i class="fal fa-expand"></i>-->
                </button>
    
                <div class="meta" v-collapse-toggle v-on:click="clicked" >
                    
                    <div class="titles">
                        <h2 v-html="title"></h2>
                        
                        <div class="data" v-if="subtitle" v-html="subtitle"></div>
    
                        <div class="data" v-if="!subtitle" >
                            {{bathrooms}} <font-awesome-icon :icon="['fas', 'circle']"  full-width></font-awesome-icon>
                            <!--<i class="fas fa-circle"></i>--> 
                            {{guests}}
                        </div>
                        
                    </div>
                    
                    <div class="info" >
                        
                        <div class="btn plus light" :class="{'active':expand}" >
                            <span v-html="buttonTitle"></span>
                            <font-awesome-icon :icon="['fal', 'plus-circle']"  full-width></font-awesome-icon>
                            <!--<i class="fal fa-plus-circle" ></i>-->
                
                        </div>
                        
                    </div>
                    
                </div>
                
            </div>
            
                
            <div class="expandable" ref="expandable"  v-collapse-content >
                
                <slot name="content"></slot>
                
            </div>
          
        </v-collapse-wrapper>

        <gallery-modal
                :data="gallery"
                :storage="storage"
                v-if="showModal && showGallery" @close="closeModal"
        >

        </gallery-modal>
        
    </div>
    
</template>

<script>
    
    import GalleryModal from './../shared/GalleryModal'

    export default {

        name: "Glance",

        components:{
            GalleryModal
        },

        props: [
            'title',
            'subtitle',
            'bathrooms',
            'guests',
            'gallery',
            'storage',
            'showGallery',
            'button-title'
        ],

        data() {
            return {
                expand:false,
                showModal:false,
            }
        },

        mounted() {


        },

        methods: {

            clicked: function(){
                this.expand = !this.expand;
            },
            closeModal: function(){
                this.showModal = false;
                this.$store.commit('storeShowingModal',false);
            }
        },

        watch: {
            // expand(value){
            //     this.collapsed = value;
            // }
        }
    }
</script>

<style scoped>

</style>