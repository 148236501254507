"use strict";


module.exports = {

    $panel: null,
    $panelScroll: null,
    $closeBtn: null,
    opened: false,

    init: function () {
        let self = this;

        self.$panel = $('#Popup');
        self.$panelScroll = $('#Popup .popup-content');
        self.$closeBtn = self.$panel.find('.closePopupBtn');

        //self.$panel.addClass('initialized');

        $('.showPopUp').on('click',function(e){
            e.preventDefault();
            let action = $(this).attr('href');
            let width = $(this).attr('data-width');

            if (!self.opened) {
                self.activate.call(self, action, width);
                //$(this).off('click');
            }
        });


        self.$closeBtn.on('click', function (e) {
            e.preventDefault();
            self.deactivate.call(self);
        });
    },

    activate: function (url, w) {
        //console.log('activate', url);
        
        let self = this;

        $('body').addClass('loadPopUp');
        self.$panel.find('.popup-content').html('');

        
        //window.RightNav.deactivate();
        
        
        $.ajax({
            method: "GET",
            url: url,
           // data: { name: "John", location: "Boston" }
        })
        .done(function( msg ) {
            //console.log( "Data Saved: " + msg );

            self.$panel.find('.popup-content').html(msg);

            $('body').removeClass('loadPopUp').addClass('showPopUp');

            $('#app').on('click', $.proxy(self.deactivate, self));

            disableBodyScroll(self.$panelScroll[0]);
        })
        .fail(function() {
            self.deactivate.call(self);
        });

        self.opened = true;



    },

    deactivate: function () {
        //console.log('sp.deactivate');
        
        let self = this;
        self.opened = false;
        enableBodyScroll(self.$panelScroll[0]);
        clearAllBodyScrollLocks();
        
        
        $('#app').off('click');

        $('body').removeClass('showPopUp');
        
        
        
        setTimeout(function () {
            self.$panel.find('.popup-content').html('');
            self.$panel.removeClass('initialized');
            clearAllBodyScrollLocks();
        }, 250);

    }

};


