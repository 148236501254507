<template>

    <div>
        <div class="hero-slider" >

            <div class="featured match-height" >

                <slick ref="slick" class="slides"
                       :style="computedFeaturedHeight"
                       :options="slickOptions"
                       @afterChange="handleAfterChange"
                       @beforeChange="handleBeforeChange"
                >

                    <!--<slot name="featured"></slot>-->
                    <hero-slide
                        v-for="(slide,index) in slides" :key="index"
                        :slide="slide"
                    ></hero-slide>

                </slick>

            </div>

            <div class="sub-featured match-height" ref="SubFeatured">

                <slick ref="slick2" class="slides"
                       :options="slickOptionsSub"
                       @afterChange="handleAfterChange"
                       @beforeChange="handleBeforeChange"
                >

                    <slot name="subfeatured"></slot>

                </slick>

            </div>

        </div>

        <div class="hero-slider-slide-chooser" ref="SlideChooser">

            <a href="#" class="choose"
                v-for="(item, index) in slides" :data-id="index"
                :class="{'active':currentSlide === (index+1)}"
                @click.prevent="changeSlide(index)"
               v-html="item.title"></a>

        </div>

    </div>

</template>

<script>
    import Slick from 'vue-slick';
    import HeroSlide from "./HeroSlide";

    export default {

        name: "hero-sliders",

        components: {HeroSlide, Slick },

        props: ['slides'],

        data() {
            return {
                slickOptions: {
                    dots:false,
                    autoplay: true,
                    autoplaySpeed: 9000,
                    arrows:false,
                    vertical:false,
                    verticalSwiping: false,
                    fade: true,
                    cssEase: 'linear',
                    zIndex:1,
                    // lazyLoad:'ondemand',
                    responsive: [
                        {
                            breakpoint: 768,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1,
                                infinite: true,
                                dots: true,
                                fade: false
                            }
                        }
                    ]
                },
                slickOptionsSub: {
                    dots:true,
                    autoplay: true,
                    autoplaySpeed: 10000,
                    arrows:false,
                    vertical:false,
                    verticalSwiping: false,
                    fade: true,
                    cssEase: 'ease',
                    zIndex:1,
                    speed:150,
                    //lazyLoad:'ondemand',
                    responsive: [
                        {
                            breakpoint: 768,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1,
                                infinite: true,
                                dots: true,
                                fade: false
                            }
                        }
                    ]
                },
                currentSlide:1,
                updatingHeight:false,
                featuredHeight:null,
                /*featureSlides:[
                    'Private Respite',
                    'National Park',
                    'Extended Stays',
                    'Family Celebrations',
                    'Montana Glamping',
                    '18 Summers'
                ]*/
            };
        },

        mounted() {
            
           // this.matchHeights();
            
            //setTimeout(this.matchHeights,1000);

            //window.addEventListener('resize', this.matchHeights );

        },

        methods: {

            matchHeights(){
                console.log('matchHeights');
                let self = this;
                
                if( $(window).width() > 767 && !this.updatingHeight ){

                    this.$refs.SlideChooser.classList.remove('enable');

                    this.updatingHeight = true;
                    
                    setTimeout(function(){

                        self.updatingHeight = false;

                        let h1 = self.$refs.SubFeatured.offsetHeight - 7.0;
      
                        //let h1 = $('#SubFeatured').height() - 7.0;
                        
                        //$('#Featured .slides').height(h1 - 5);
                        self.featuredHeight = h1 - 5;
                        console.log('HEIGHT',h1);
                        //$('#SlideChooser').addClass('enable');
                        self.$refs.SlideChooser.classList.add('enable');
                        
                    },500);
                }else{
                    this.featuredHeight = null;
                }
            },

            handleAfterChange(event, slick, currentSlide) {

                // if(this.$el.querySelectorAll('.slide').length > 1 ) {
                //     this.$el.querySelectorAll('.slide')[currentSlide + 1].classList.add('ready');
                // }

                //$('#SlideChooser').find('a').removeClass('active').eq(currentSlide).addClass('active');
                this.currentSlide = this.$refs.slick.currentSlide() + 1;

            },

            handleBeforeChange(event, slick, currentSlide, nextSlide) {
                // if(this.$el.querySelectorAll('.slide').length > 1 ) {
                //     this.$el.querySelectorAll('.slide')[currentSlide + 1].classList.remove('ready');
                // }
            },

            changeSlide(index){

                this.$refs.slick.goTo(index, true)

            },


            next() {
                this.$refs.slick.next();
            },

            prev() {
                this.$refs.slick.prev();
            },
            handleLazyLoad(event, slick, image, imageSource) {
                // console.log('handleLazyLoaded', event, slick, image, imageSource);
            }
        },
        
        computed: {
            computedFeaturedHeight(){
                return this.featuredHeight ? {
                    height: this.featuredHeight + 'px',
                }:{}
            }
        }

        // beforeUpdate() {
        //     console.log('beforeUpdate');
        //     if (this.$refs.slick) {
        //         this.$refs.slick.destroy();
        //     }
        // },
        // updated() {
        //     console.log('updated');
        //
        //     this.$nextTick(function () {
        //         if (this.$refs.slick) {
        //             console.log('re-creating');
        //             this.$refs.slick.create(this.slickOptions);
        //         }
        //     });
        // },
    }
</script>

<style scoped>

</style>
