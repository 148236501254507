<template>

    <div class="expandable" :class="{'active':expand}">
        
        <v-collapse-wrapper ref="collapseWrapper" @afterToggle="handleToggle">

            <div class="top" >

                <div class="meta" v-collapse-toggle  >
                    
                    <div class="titles">
                      
                      <h5 v-html="camp.subtitle"></h5>

                      <h2 v-html="camp.title"></h2>
                                                 
                    </div>
                    
                    <div class="info" >
                        
                        <div class="btn plus" :class="{'active':expand}" >
                            <span v-html="computerButtonTitle"></span>
                            <font-awesome-icon :icon="['fal', 'plus-circle']"  full-width></font-awesome-icon>
                        </div>
                        
                    </div>
                    
                </div>
                
            </div>

            <div class="" ref="expandable"  v-collapse-content >

	            <div class="content">

		            <picture>
			            <source :srcset="camp.imageRoot + '.jpg'" media="(min-width: 768px)">
			            <source :srcset="camp.imageRoot + '-m.jpg'" media="(min-width: 10px)">
			            <img :srcset="camp.imageRoot + '.jpg'" alt="" class="img-fluid">
		            </picture>
		            
	                <div class="meta-window " :class="camp.windowClass">

		            <h5  v-html="camp.subtitle"></h5>
		            <h2 v-html="camp.title"></h2>
		            <p v-html="camp.copy"></p>

		            <p>
			            Maximum Occupancy: <span v-html="camp.maxOccupancy"></span>
		            </p>


		            <a class="btn" :href="camp.route">
			            Explore North Bank Camp
			            <font-awesome-icon :icon="['fal', 'arrow-circle-right']"  full-width></font-awesome-icon>
		            </a>


	            </div>
		            
	            </div>
	            
            </div>
          
        </v-collapse-wrapper>

    </div>
    
</template>

<script>
    

    export default {

        name: "ReserveCampSlice",

        components:{
        },

        props: [
        	'camp',
	        'toggle',
        ],

        data() {
            return {
                expand:false,
                computerButtonTitle:'Detail',
            }
        },

        mounted() {
        

        },

        methods: {

            handleToggle: function(){
                this.expand = !this.expand;
                if( this.expand ) this.$emit('expanded',this.camp.index);

            },

          
        },

        watch: {
            expand(value) {
                if(value){
                    this.computerButtonTitle = 'Close';
                }else{
                  this.computerButtonTitle =  'Detail';
                }
            }
        }
    }
</script>

<style scoped>

</style>