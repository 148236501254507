

module.exports = {

    $nav: null,
    $btn: null,
    $closeBtn: null,
    opened: false,
    activeClass:'',

    init: function ($nav, $btn, $closeBtn, activeClass) {
        
     //   console.log("init r-nav", $nav);
        
        let self = this;

        self.$nav = $nav;
        self.$btn = $btn;
        self.$closeBtn = $closeBtn;
        self.activeClass = activeClass;
        
        self.$btn.on('click', function (e) {
            e.preventDefault();
            if (!self.opened) {
                self.activate.call(self);
                $(this).off('click');
                $(this).on('click', $.proxy(self.deactivate, self));
            }

        });

        self.$closeBtn.on('click', function (e) {
            e.preventDefault();
            self.deactivate.call(self);
        });
        
        let edgeH = self.$btn.height();
        let edgeP = self.$btn.offset().top;
        let edgeCutoff = edgeH - edgeP;
        
        $(window).on('scroll', function(e){
            
            let p = $(window).scrollTop();
            
            if( p > edgeCutoff){
                self.$btn.addClass('cutoff');
            }else{
                self.$btn.removeClass('cutoff');
            }
            
        })
    },

    activate: function () {
        //console.log('activate');

        let self = this;
        let edgeP = self.$btn.offset().top;
        let navY = $( window ).scrollTop() - edgeP + 48;
        self.opened = true;

        disableBodyScroll(self.$nav[0]);
        
        // position right nav to the top
        if( $( window ).scrollTop() + 48 > edgeP ){
            self.$nav.css("top", navY);
        }

        // set right nav height to fill the area available
        let navH = $( window ).height() - (self.$nav.offset().top - $( window ).scrollTop());
        self.$nav.css("height", navH);
        
        // because adding the event handler immediately will case the activate not to happen
        // place it in a quick timer.
        setTimeout(function () {
            $('main > section, #Hero').on('click', $.proxy(self.deactivate, self));
        }, 250);

        // activate the main classes
        document.querySelector('body').classList.add(self.activeClass);

    },

    deactivate: function () {
        let self = this;
        //console.log('deactivate', self.$nav);

        if( self.$nav != null){
            
          //  console.log('odd');
            
            self.opened = false;

    
            enableBodyScroll(self.$nav[0]);

            self.$nav.css("height",'100%');
            self.$nav.css("top",'0');
    

            clearAllBodyScrollLocks();

            document.querySelector('body').classList.remove(self.activeClass);


            $('main > section, #Hero').off('click');

            // re-attach click handler to element that initiates the opening:
            self.$btn.on('click', function (e) {
                e.preventDefault();
                $(this).off('click');
                self.activate.call(self);
            });

            setTimeout(function () {
                clearAllBodyScrollLocks();
            }, 500);
        }

    }

};

