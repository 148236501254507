<template>
    <a
        :href="data.page_link ? data.page_link : '#'" class="event-bin  bin">

        <div class="imgWrap">
            <img :data-src="data.square_bin_path" :alt="data.title" class="img-fluid lazy lazy-hidden"/>
            
            <!--<v-lazy-image
                 :src="data.square_bin_path"
                 :alt="data.title"
            ></v-lazy-image>-->
            
            
        </div>

        <div class="meta">

            
            <h5 class="series" v-if="data.category_title">
                <span  v-html="data.category_title">&nbsp;</span>
            </h5>
            

            <h5 class="series" v-if="!data.category_title && data.inclusive && !data.guided">
                <span>Inclusive Activity</span>
            </h5>
            
            <h5 class="series" v-if="!data.category_title && data.guided && data.inclusive">
                <span>Inclusive or Guided Activity</span>
            </h5>
            
            <h5 class="series" v-if="!data.category_title && data.guided && !data.inclusive">
                <span>Guided Activity</span>
            </h5>
            
            <h5 class="series" v-if="!data.category_title && !data.guided && !data.inclusive"></h5>
            
            
            <h3 v-html="data.title"></h3>

            <h4 v-if="data.sub_title" v-html="data.sub_title"></h4>
            
            
            
            
  <!--          @isset($age)

            @if( is_array($age) )

            <div class="age-icon-group">
                @foreach($age as $a)

                <img class="age-icon" src="{{Storage::url('static/activities/age-icon-'.$a.'-white.png')}}"/>
                @if($loop->first)
                <span>and</span>
                @endif

                @endforeach
            </div>

            @else
            <img class="age-icon" src="{{Storage::url('static/activities/age-icon-'.$age.'-white.png')}}"/>

            @endif

            @endisset
-->

            <font-awesome-icon :icon="['fal', 'angle-double-right']"  full-width></font-awesome-icon>

<!--            <div class="footer">
                <div class="type">
                    <h5 class="series" v-if="data.inclusive && !data.guided">Inclusive Activity</h5>
                    <h5 class="series" v-if="data.guided && data.inclusive">Inclusive or Guided Activity</h5>
                    <h5 class="series" v-if="data.guided && !data.inclusive">Guided Activity</h5>
                    <h5 class="series" v-if="!data.guided && !data.inclusive">&nbsp;</h5>
                </div>

                &lt;!&ndash;            <div class="type age">
                                <h5 class="series">
                                    <strong>{{data.age_label}}</strong>
                                </h5>
                                
                            </div>&ndash;&gt;

            </div>-->
            
            
            
            
            
        </div>
        <div class="footer">
            <div class="type">
                {{data.availability}}
            </div>
            <div class="type age">
                {{data.age_label}}
            </div>

        </div>
    </a>
    
</template>

<script>

    export default {

        name: "ActivityBin",

        props: ['data'],

        data() {
            return {
                
            }
        },

        mounted() {
            /*this.$nextTick(() => {
                
            });*/
            
            //console.log('me', this.data);

        },

        methods: {},
    }
</script>

<style scoped>

</style>