<template>
    
    <div>

        <a class="btn " href="#" @click.prevent="$store.commit('showGroupsCateringModal',true)">
            <i class="fas fa-fw fa-file"></i>
            Sample Catering Menu
        </a>



        
    </div>
    
</template>

<script>
    import GroupsSampleCateringMenuModal from './GroupsSampleCateringMenuModal'
    
    export default {

        name: "GroupsSampleCateringMenuButton",

        props: [],
        
        components: {
            GroupsSampleCateringMenuModal
        },
        
        data() {
            return {
                showModal:false,
            }
        },

        mounted() {


        },

        methods: {},
    }
</script>

<style scoped>

</style>