<template>

	<div class="navlink">

		<div class="title" v-html="title" @click="handleClick"></div>


		<div class="panel" :class="{'active':active}">
			<slot></slot>
		</div>


	</div>

</template>

<script>

import { mapState, mapGetters, mapMutations } from 'vuex';

export default {

	name: "NavLink",

	props: {
		item: Object,
		title: String,
		active:false
	},

	data() {
		return {

		}
	},

	mounted() {


	},

	methods: {

		...mapMutations(['setMobileNavSubmenuOn']),

		handleClick(){

			this.$emit('active',!this.active);

			this.setMobileNavSubmenuOn(true);

		}

	},

	computed: {

		//...mapState(['locale','storage','isMobile']),


	},

	watch: {},
}
</script>

<style scoped lang="scss">
//@import "./resources/sass/variables";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

	.navlink{
		display: block;
		margin-bottom:1rem;
		text-transform:uppercase;
		color:#e9dec4;
	}

	.panel{
		display: flex;
		position: fixed;
		z-index: 1;
		top:0;
		left:1.25rem ;
		width:320px;
		height: 100vh;
		overflow-y: scroll;
		background-color: #181610;
		transform:translateX(300px);
		transition:transform 0.2s ease;
		flex-direction: column;

		&.active{

			transform:translateX(-1.25rem);
		}

		.nav-drawer{
			ul.thumbs{
				display:flex;
				//flex-direction: column;

				flex-wrap: wrap;
				margin:0 -0.5rem;
				> li{
					width:calc(50% - 0.5rem);
					margin:0.25rem;

					&:first-child{
						a{
							img{
								width: 100%;
								height: 210px;
								object-fit: cover;
							}
						}
					}

					&:last-child, &:first-child{
						width:calc(100% - 0.5rem);
					}

					a{
						//display: flex;
						//align-items: center;
						background-color:#13120f;
						img{
							//width:90px;
						}

						.meta{
							justify-content: center;
							text-align: center;
							padding:0.375rem 0;
						}
					}


				}
			}

		}

	}


</style>