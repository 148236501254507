export default {

    inViewport (el) {
        var rect = el.getBoundingClientRect()
        return !(rect.bottom < 0 || rect.right < 0 ||
            rect.left > window.innerWidth ||
            rect.top > window.innerHeight)
    },

    bind(el, binding, vnode) {

        el.classList.add('before-enter')
        el.$onScroll = function() {
            if (binding.def.inViewport(el)) {

                if(binding.expression){
                    vnode.context.$emit(binding.expression);
                }


                if(binding.value){
                    binding.value.call();
                }


                el.classList.add('enter')
                el.classList.remove('before-enter')
                binding.def.unbind(el, binding)


            }
        }
        document.addEventListener('scroll', el.$onScroll);

    },

    inserted(el, binding) {
        el.$onScroll()
    },

    unbind(el, binding) {
        document.removeEventListener('scroll', el.$onScroll)
        delete el.$onScroll
    }


};
